.blog-post-details-container {
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 20px;
  /* margin-bottom: 20px; */
  padding: 30px;
  color: white;
  background-color: black;
  border: 1px solid white;
  overflow: hidden;
  border-radius: 20px;
}

.blog-post-details-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: brightness(0.5) blur(2px);
}

.blog-post-image {
  display: flex;
  flex-direction: column;
  width: 20%;
  margin-block: auto;
  z-index: 2;
}

.blog-post-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.blog-post-image input {
  margin-top: 10px;
  width: 95%;
  margin-inline: auto;
  padding: 5px;
  border: 1px solid #7c7c7c;
  border-radius: 5px;
  outline: none;
}

.blog-post-details {
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 0.5rem;
  margin-top: 10px;
  z-index: 2;
}

.blog-post-details.readonly {
  width: calc(100% - 15px);
}

.blog-post-share {
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-40px, -50%);
  text-wrap: nowrap;
  z-index: 5;
}

.blog-post-share .share-button {
  position: relative;
  padding: 5px 0px;
  margin-bottom: 7.5px;
}

.blog-post-share .share-button button {
  display: flex;
  align-items: center;
  transition-duration: 0.5s;
  height: 40px;
  border-radius: 20px 0 0 20px;
}

.blog-post-share .share-button:hover button {
  transform: translateX(calc(-100% + 40px));
}

.blog-post-share i {
  font-size: 1.4em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-inline: 5px;
  margin-block: 5px;
}

.blog-post-share span {
  margin-right: 10px;
}

.share-button.facebook button {
  background-color: #4267b2 !important;
}

.share-button.twitter button {
  background-color: #1da1f2 !important;
}

.share-button.whatsapp button {
  background-color: #25d366 !important;
}

.share-button.telegram button {
  background-color: #0088cc !important;
}

.blog-input-label {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.blog-input-label .readonly {
  background-color: transparent;
  border: none;
  color: white;
}

.blog-post-details input,
.blog-post-details textarea {
  padding: 5px;
  border: solid 1px rgb(124, 124, 124);
  border-radius: 5px;
  outline: none;
  width: 100%;
  font-family: "Rubik", monospace;
  font-size: 0.8em;
}

.blog-post-details textarea {
  height: 80px;
  resize: none;
}

.blog-post-title.readonly {
  font-size: 2.3rem;
  font-weight: 800;
  padding: 5px;
  width: 100%;
  font-family: "Arial", monospace;
  text-transform: capitalize;
  word-wrap: break-word;
  height: fit-content;
  margin-bottom: 0.1em;
  cursor: default;
}

.blog-input-label:last-child>div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.blog-post-details.readonly .blog-tag-button {
  border: 1px solid rgb(179, 179, 179);
  background: rgba(0, 0, 0, 0.55);
  color: white;
}

.blog-active-tag {
  background-color: #000025;
  color: white;
}

.blog-active-tag:hover {
  background-color: #000025c7;
  color: white;
}

@media (max-width: 1100px) {
  .blog-post-image {
    width: 25%;
  }

  .blog-post-details {
    width: 75%;
  }

  .blog-post-title.readonly {
    font-size: 1.8rem;
  }
}

@media (max-width: 850px) {
  .blog-post-details-container {
    flex-direction: column;
    gap: 20px;
  }

  .blog-post-readonly-image {
    display: none;
  }

  .blog-post-image,
  .blog-post-details {
    width: 100%;
  }

  /* .blog-post-image {
    width: 35%;
  }
  .blog-post-details {
    width: 65%;
  } */
  .blog-post-title.readonly {
    font-size: 1.7rem;
  }
}

/* @media (max-width: 650px) {
  .blog-post-details-container {
    flex-direction: column;
    gap: 20px;
  }
  .blog-post-readonly-image {
    display: none;
  } 
  .blog-post-image,
  .blog-post-details {
    width: 100%;
  }
} */

@media (max-width: 450px) {

  .blog-post-details input,
  .blog-post-details textarea {
    font-size: 0.7em;
  }
}

@media (max-width: 350px) {
  .blog-post-details .blog-tag-button {
    font-size: 0.7em;
  }

  .blog-post-title.readonly {
    font-size: 1.5rem;
  }
}