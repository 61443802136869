@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css?family=Bruno+Ace");

.latest-updates-container {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	gap: 3rem;
	padding-top: 1.5rem;
	padding-bottom: 2rem;
}

.latest-updates-title {
	position: relative;
	text-align: center;
}

.latest-updates-title b {
	font-size: 3.4rem;
	color: white;
	font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode",
		"Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.latest-updates-title button {
	position: absolute;
	top: calc(50% - 1.25em);
	left: calc(100% + 15vw);
	font-size: 1.1rem;
	width: max-content;
}

.latest-updates-content {
	width: 90vw;
	margin: 0 auto;
	display: flex;
	row-gap: 1vw;
	justify-content: space-evenly;
	font-family: "Rubik", sans-serif;
	flex-wrap: wrap;
	height: max-content;
	z-index: 3;
}

.latest-updates-subflex1,
.latest-updates-subflex2,
.latest-updates-subflex3,
.latest-updates-subflex4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-direction: column;
	width: 23%;
	height: 100%;
	row-gap: 1.5vw;
}

.latest-updates-title button,
.more-updates-button {
	padding: 0.7rem;
	border-radius: 10px;
	font-family: "Rubik", sans-serif;
	background: black;
	box-shadow: 0 0 15px rgb(0, 168, 235);
	color: #ccc;
	border-style: solid;
	border-width: 0.5px;
	border-color: #00a8eb;
	transition: 0.5s ease;
}

.latest-updates-title button:hover,
.more-updates-button:hover {
	scale: 1.05;
	border-color: rgb(157, 59, 255);
	box-shadow: 0 0 20px rgb(157, 59, 255);
	cursor: pointer;
}

.more-updates-button {
	font-size: 1.3rem;
}

.more-updates-button i {
	color: cyan;
	transition: 0.5s ease;
}
.more-updates-button i:nth-child(1) {
	margin-right: 0.5em;
}
.more-updates-button i:nth-child(2) {
	margin-left: 0.5em;
}

.more-updates-button:hover i {
	color: rgb(157, 59, 255);
}

.latest-updates-modal-holder {
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background: rgba(11, 11, 11, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1rem;
}
@keyframes latest-update-modal-appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.latest-updates-modal {
	/* background-color: rgba(214, 214, 214, 0.25); */
	border-radius: 2em;
	width: calc(70vw + 4em);
	height: fit-content;
}

.latest-updates-modal-inner {
	background-color: rgb(17, 17, 17);
	position: relative;
	border-radius: 1em;
	margin: 2em;
	font-family: "Rubik", sans-serif;
	box-sizing: border-box;
	animation: latest-update-modal-appear 0.5s linear 0.5;
	color: black;
	box-shadow: 0 0 15px rgb(49, 49, 49), 0 0 20px rgb(83, 83, 83);
}

.latest-updates-modal-title {
	font-size: 2.2em;
	text-align: center;
	font-weight: 800;
	padding: 0.7em;
	margin-bottom: 0.5em;
	border-top-left-radius: 0.45em;
	background: rgb(3, 137, 155);
}

.latest-updates-modal-content {
	max-height: 53vh;
	overflow-y: auto;
	margin: 0 1.2em;
	padding: 0.6em;
	padding-bottom: 0;
}
.latest-updates-modal-content::-webkit-scrollbar {
	width: 7px;
	margin-left: 0.5em;
}
/* Track */
.latest-updates-modal-content::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3.5px grey;
	border-radius: 10px;
}
/* Handle */
.latest-updates-modal-content::-webkit-scrollbar-thumb {
	background: rgb(174, 174, 174);
	border-radius: 10px;
}
/* Handle on hover */
.latest-updates-modal-content::-webkit-scrollbar-thumb:hover {
	background: gray;
}

.latest-updates-modal-img {
	width: 33%;
	float: left;
	margin: 0;
	padding: 3px;
	margin-right: 1.1em;
	margin-bottom: 0.9em;
	border: solid 1px black;
	border-radius: 0.75em;
	box-shadow: inset 0 0 5px rgb(80, 80, 80), inset 0 0 7px rgb(131, 131, 131),
		0 0 9px rgb(31, 31, 31), 0 0 18px rgb(51, 51, 51);
}

.latest-updates-modal-content p {
	padding-top: 0.2em;
	font-size: 1.2em;
	text-align: justify;
	color: rgb(188, 188, 188);
}

/* .latest-updates-modal-content a:active {
  color: rgb(157, 0, 242);
}
.latest-updates-modal-content a:visited {
  color: rgb(157, 0, 242);
} */

.latest-updates-modal-content a {
	padding: 0.4em 0.8em;
	background-color: rgb(0, 123, 255);
	color: white;
	text-decoration: none;
	border-radius: 12px;
	transition-duration: 0.35s;
	border: solid 1px rgb(120, 185, 255);
}

.latest-updates-modal-content a:hover {
	background-color: rgb(0, 195, 255);
	box-shadow: 0 0 10px rgb(0, 195, 255);
	border-color: rgb(0, 123, 255);
}

.latest-updates-modal-footer {
	clear: both;
	text-align: right;
	margin-top: 0.75em;
	padding: 1.5em;
	padding-top: 0;
	padding-right: 3.5em;
	font-size: 1.1em;
	color: rgb(114, 114, 114);
}

.latest-updates-modal-button {
	position: absolute;
	background-color: rgb(17, 17, 17);
	height: 2em;
	width: 2em;
	/* aspect-ratio: 1/1; */
	border-radius: 50%;
	border-style: solid;
	border-color: rgb(3, 137, 155);
	top: -0.6rem;
	right: -0.6rem;
	text-align: center;
	line-height: 2em;
	font-size: 1.3rem;
	cursor: pointer;
	transition-duration: 0.25s;
	color: white;
	box-shadow: 0 0 5px rgb(41, 41, 41), 0 0 12px rgb(83, 83, 83);
}
.latest-updates-modal-button i {
	transition-duration: 0.25s;
}

.latest-updates-modal-button:hover {
	color: rgb(201, 63, 63);
}
.latest-updates-modal-button:hover i {
	scale: 1.2;
}

@media screen and (max-width: 1200px) {
	.latest-updates-title button {
		left: calc(100% + 4vw);
	}
	.latest-updates-subflex1,
	.latest-updates-subflex2,
	.latest-updates-subflex3 {
		width: 31%;
	}
	.latest-updates-modal {
		width: calc(85vw + 4em);
	}
	.latest-updates-modal-img {
		width: 38%;
	}
}

@media screen and (max-width: 1000px) {
	.latest-updates-modal {
		font-size: 0.95rem;
	}
}

@media screen and (max-width: 850px) {
	.latest-updates-title {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		align-items: center;
	}
	.latest-updates-title button {
		position: static;
	}
	.latest-updates-subflex1,
	.latest-updates-subflex2 {
		width: 47%;
		row-gap: 2vw;
	}
	.latest-updates-title b {
		font-size: 2.7rem;
	}
	.latest-updates-modal {
		font-size: 0.9rem;
	}
	.latest-updates-modal-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.latest-updates-modal-img {
		width: 58%;
		margin-bottom: 1em;
	}
}

@media screen and (max-width: 600px) {
	.latest-updates-subflex1 {
		width: 70%;
	}
	.latest-updates-modal {
		font-size: 0.85rem;
	}
	.latest-updates-modal-img {
		width: 70%;
	}
	.latest-updates-modal-button {
		height: 1.7em;
		line-height: 1.7em;
	}
}

@media screen and (max-width: 460px) {
	.latest-updates-subflex1 {
		width: 85%;
	}
	.latest-updates-title b {
		font-size: 2.5rem;
	}
	.more-updates-button {
		font-size: 0.9rem;
	}
	.latest-updates-modal {
		font-size: 0.7rem;
	}
	.latest-updates-modal-img {
		width: 85%;
	}
}

@media screen and (max-width: 350px) {
	.latest-updates-modal {
		font-size: 0.65em;
	}
	.latest-updates-modal-img {
		width: 85%;
	}
}

@media screen and (max-width: 310px) {
	/* .latest-updates-title b {
    font-size: 1.7rem;
  } */
	.more-updates-button {
		font-size: 0.75rem;
	}
}
