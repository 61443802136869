.Events2 {
  display: flex;
  flex-direction: column;
  /* background-color: #76ace1; */
  font-family: "Rubik", monospace, sans-serif;
}

.events-content2,
.events-content1 {
  height: 100vh;
  min-height: 600px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 4vh;
}

.slider1 {
  width: 50vw;
  text-align: center;
  height: max(50vh, 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto auto;
}

.slider-inner {
  position: relative;
  width: fit-content;
}

.image-events {
  max-width: 100%;
  max-height: 100%;

  /* width: 50vw;
    height: max(50vh, 280px); */
  /* position: fixed; */

  object-fit: cover;
  display: block;
  opacity: 1;
  margin: 0 auto;
  animation: fade-in 0.5s linear;
}

.fade-out {
  opacity: 0;
  display: none;
  animation: fade-out 0.1s linear;
}

.arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  font-size: 25px;
  background: transparent;
  border: none;
  height: 1.5em;
  width: 1.5em;
  /* aspect-ratio: 1/1; */
  border-radius: 50%;
  color: black;
  background-color: #f5fefd;
  cursor: pointer;
  outline: none;
}

.arrow-left {
  right: calc(100% + 0.5em);
}

.arrow-right {
  left: calc(100% + 0.5em);
}

.arrow-black {
  color: #f2f7f6;
  background-color: black;
}

@keyframes fade-out {
  0% {
    display: block;
    opacity: 1;
  }

  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

.events-content2 {
  background-color: #f1f8f7;
}

.text-box1 h3 {
  /* width: 100%; */
  /* background-color: #000000; */
  padding-top: 0.8em;
  padding-bottom: 0.5em;
  letter-spacing: 10px;
  color: #f5fefd;
  text-transform: uppercase;
  text-align: center;
  font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-size: 2em;
  font-weight: 400;
}

.text-box1 {
  /* padding-top: 05em; */
  position: relative;
  text-align: center;
  text-align: justify;
  /* line-height: 30px; */
  /* margin-left: 3em; */
  margin-left: 4em;
  margin-right: 4em;
  font-size: 1.3em;
  color: #f5fefd;
}

.slider2 {
  width: 50vw;
  text-align: center;
  height: max(50vh, 280px);
  margin: auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Events2 .fade-out {
  opacity: 0;
}

/* .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: transparent;
    border: none;
    color: #ffffff;
    cursor: pointer;
    outline: none;
  } */

@keyframes fade-in {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.text-box2 h3 {
  padding-top: 0.8em;
  padding-bottom: 0.5em;
  letter-spacing: 10px;
  color: #000000;
  text-transform: uppercase;
  /* text-align: center;*/
  font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode",
    "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-size: 2em;
  font-weight: 400;
}

.text-box2 {
  position: relative;
  text-align: center;
  text-align: justify;
  margin-left: 4em;
  margin-right: 4em;
  font-size: 1.3em;
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 1760px) and (min-width: 1281px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 47vw;
    text-align: center;
    height: max(50vh, 280px);
    text-align: center;
    margin: auto auto;
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    text-align: center;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    font: 1.3rem;
    text-align: center;
    font-size: 2.6em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.3em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em; */
    /* padding-bottom: 3em; */
    position: relative;
    width: 47vw;
    text-align: center;
    height: max(50vh, 280px);
    text-align: center;
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    font: 1.3rem;
    text-align: center;
    font-size: 2.6em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.3em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 1281px) and (min-width: 1025px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 50vw;
    text-align: center;
    height: max(50vh, 280px);
    text-align: center;
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    text-align: center;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    text-align: center;
    font-size: 2.5em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.3em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em; */
    position: relative;
    width: 50vw;
    text-align: center;
    height: max(50vh, 280px);
    text-align: center;
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.5em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.3em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 1025px) and (min-width: 913px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 50vw;
    text-align: center;
    height: max(40vh, 220px);
    text-align: center;
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    text-align: center;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.5em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.2em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em; */
    position: relative;
    width: 50vw;
    text-align: center;
    height: max(40vh, 220px);
    text-align: center;
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.5em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.2em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 913px) and (min-width: 821px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 55vw;
    text-align: center;
    height: 45vh;
    text-align: center;
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    text-align: center;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.25em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.2em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em; */
    position: relative;
    width: 55vw;
    text-align: center;
    height: 45vh;
    text-align: center;
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.25em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.2em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 821px) and (min-width: 769px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 60vw;
    text-align: center;
    height: max(40vh, 220px);
    text-align: center;
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    text-align: center;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.25em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.17em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em; */
    position: relative;
    width: 60vw;
    text-align: center;
    height: max(40vh, 220px);
    text-align: center;
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.25em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.17em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 769px) and (min-width: 542px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 60vw;
    text-align: center;
    height: max(40vh, 220px);
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.18em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.1em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em; */
    position: relative;
    width: 60vw;
    text-align: center;
    height: max(40vh, 220px);
    text-align: center;
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.18em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 4em;
    margin-right: 4em;
    font-size: 1.1em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 541px) and (min-width: 481px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 65vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 260px; */
  }

  .image-events {
    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .arrow {
    display: none;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.18em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.06em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em; */
    position: relative;
    width: 65vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 260px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 2.18em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1.06em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 481px) and (min-width: 415px) {
  .slider1 {
    /* padding-top: 3em; */
    position: relative;
    width: 75vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 220px; */
  }

  .image-events {
    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .arrow {
    display: none;
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.85em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em; */
    position: relative;
    width: 75vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 220px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.85em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 1em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 415px) and (min-width: 395px) {
  .slider1 {
    /* padding-top: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 150px; */

    /* padding-top: 3em; */
    position: relative;
    width: 75vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 220px; */
  }

  .arrow {
    display: none;
  }

  .image-events {
    max-width: 100%;
    max-height: 100%;

    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.9em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 0.95em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 120px;
    text-align: center; */

    /* padding-top: 3em; */
    position: relative;
    width: 75vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 220px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.9em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 0.95em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 394px) and (min-width: 364px) {
  .slider1 {
    /* padding-top: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 150px; */

    /* padding-top: 3em; */
    position: relative;
    width: 75vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 215px; */
  }
  .arrow {
    display: none;
  }

  .image-events {
    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.7em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 0.89em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 120px;
    text-align: center; */

    /* padding-top: 3em; */
    position: relative;
    width: 75vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 215px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.7em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 2em;
    margin-right: 2em;
    font-size: 0.89em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 364px) and (min-width: 334px) {
  .events-content2,
  .events-content1 {
    min-height: 740px;
  }
  .slider1 {
    /* padding-top: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 150px; */

    /* padding-top: 3em; */
    position: relative;
    width: 80vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 140px; */
  }
  .arrow {
    display: none;
  }

  .image-events {
    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.5em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-size: 0.9em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 120px;
    text-align: center; */

    /* padding-top: 3em; */
    position: relative;
    width: 80vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 140px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.5em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-size: 0.9em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 334px) and (min-width: 290px) {
  .events-content2,
  .events-content1 {
    min-height: 740px;
  }
  .slider1 {
    /* padding-top: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 150px; */

    /* padding-top: 3em; */
    position: relative;
    width: 80vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 140px; */
  }
  .arrow {
    display: none;
  }

  .image-events {
    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.35em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-size: 0.9em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 120px;
    text-align: center; */

    /* padding-top: 3em; */
    position: relative;
    width: 80vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 140px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.35em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-size: 0.9em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (max-width: 290px) {
  .slider1 {
    /* padding-top: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 150px; */

    /* padding-top: 3em; */
    position: relative;
    width: 80vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 100px; */
  }
  .arrow {
    display: none;
  }

  .image-events {
    /* width: 50vw;
      height: max(50vh, 280px); */
    /* position: fixed; */

    object-fit: cover;
    position: absolute;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
    /* text-align: center; */
  }

  .text-box1 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000;
    text-transform: uppercase;
    font: 1.3rem;
    text-align: center; */
    font-size: 1.2em;
  }

  .text-box1 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-size: 0.85em;
    /* color: rgb(0, 0, 0); */
  }

  .slider2 {
    /* padding-top: 3em;
    padding-bottom: 3em;
    position: relative;
    width: 50vw;
    text-align: center;
    height: 120px;
    text-align: center; */

    /* padding-top: 3em; */
    position: relative;
    width: 80vw;
    /* text-align: center; */
    height: max(30vh, 160px);
    /* padding-right: 100px; */
  }

  .text-box2 h3 {
    padding-top: 0.8em;
    padding-bottom: 0.5em;
    letter-spacing: 10px;
    /* color: #000000; */
    text-transform: uppercase;
    /* font: 1.3rem; */
    text-align: center;
    font-size: 1.2em;
  }

  .text-box2 {
    position: relative;
    text-align: center;
    text-align: justify;
    margin-left: 1.2em;
    margin-right: 1.2em;
    font-size: 0.85em;
    /* color: rgb(0, 0, 0); */
  }
}

@media screen and (min-height: 810px) {
  .Events2 {
    font-size: 1.1rem;
  }
}

@media screen and (min-height: 1000px) {
  .Events2 {
    font-size: 1.17rem;
  }
}

@media screen and (min-height: 1200px) {
  .Events2 {
    font-size: 1.42rem;
  }
}

@media screen and (min-height: 1300px) {
  .Events2 {
    font-size: 1.55rem;
  }
}
