@import url(https://fonts.googleapis.com/css?family=Open+Sans:600);

.about {
	font-family: "Open Sans", "sans-serif";
	flex: 1;
	width: auto;
	/* background-color: rgb(0, 0, 0); */
	/* background-image: url("https://images.unsplash.com/photo-1578662996442-48f60103fc96?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTZ8fHxlbnwwfHx8fA%3D%3D&w=1000&q=80"); */
	/* background-image: url("/public/img-about/background-about.jpg"); */
	display: flex;
	overflow: hidden;
}

.about-sidebar {
	height: 100%;
	width: 10%;
	border-right: 1px solid #575757;
	font-size: medium;
	display: flex;
	justify-content: center;
	align-items: center;
}

.about-sidebar-content {
	display: flex;
	flex-direction: column;
	gap: 10vh;
	width: 100%;
	text-align: center;
	/* border-right: 10px solid white;
  border-image: linear-gradient(to bottom, white 30%, transparent 30%) 1 100%; */
}

.about-sidebar-element {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	height: auto;
	width: auto;
	color: rgb(104, 104, 104);
	opacity: 0.8;
	font-size: 1vw;
	margin: 0px 15%;
	padding: 3px;
	cursor: pointer;
	transition: all 0.8s ease-in-out;
}
.about-sidebar-element.active {
	color: white;
	opacity: 1;
	font-size: 1.1vw;
}

.about-sidebar-element h1 {
	font-family: "Bruno Ace";
}

.about-main {
	position: relative;
	height: 100%;
	width: 87%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	mix-blend-mode: difference;
	overflow: hidden;
}

.about-main-content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	height: 100%;
	padding: 5vh;
	gap: 111vh;
}

.about-content-element {
	position: relative;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 74vh;
	word-spacing: 1.8px;
	letter-spacing: 1px;
	text-align: center;
	/* background-color: rgba(45, 42, 42, 0.5); */
	border-radius: 10px;
}
.about-main-heading {
	position: relative;
	height: 14%;
	top: 0%;
}
.about-content-element h1 {
	font-size: 2.5rem;
	font-weight: bolder;
	margin-bottom: 1vh;
	color: white;
	display: inline;
	letter-spacing: 5.2px;
	text-shadow: 0px 0px 5px rgb(202 196 196), 0px 0px 8px rgb(112 105 105);
	font-family: "Bruno Ace";
}
.about-main-para {
	position: relative;
	height: fit-content;
	border-radius: 10px;
	overflow: auto;
	overscroll-behavior-block: contain;
}
.about-main-para::-webkit-scrollbar {
	width: 5px;
	border-radius: 10px;
}
.about-main-para::-webkit-scrollbar-thumb {
	background-color: rgb(16, 165, 140);
	border-radius: 10px;
}
.about-main-para::-webkit-scrollbar-track {
	background-color: rgba(94, 92, 92, 0.5);
	border-radius: 10px;
}
.about-main-para::-webkit-scrollbar-thumb:hover {
	background: rgb(24, 110, 96);
}

.about-content-element p {
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 1.5;
	padding: 0px 15px;
	text-align: justify;
	text-align-last: center;
	text-shadow: 0px 0px 2px rgb(130 156 166);
	transition: all 0.5s ease-in-out;
	font-family: "Rubik";
}

.about-social-media-icons {
	height: 100%;
	width: 0%;
	font-size: medium;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5vh;
}

.about-social-media-icons-link {
	font-size: 1.2rem;
}

.about-social-media-icons-link a {
	position: relative;
	display: block;
	width: auto;
	height: auto;
	padding: 7px;
	background: #000000;
	border-radius: 40%;
	color: #d0d0d0;
	transition: 0.5s;
}

/* .about-social-media-icons-link a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 40%;
  background: rgb(16, 165, 140);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.about-social-media-icons-link a:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px rgb(16, 165, 140);
} */

.about-social-media-icons-link a:hover {
	color: rgb(16, 165, 140);
	/* box-shadow: 0 0 5px rgb(16, 165, 140); */
	text-shadow: 0 0 5px rgb(16, 165, 140);
}
.about-downarrow {
	display: none;
}

/* Responsiveness*/
@media (max-width: 645px) {
	.about-sidebar-element {
		font-size: 6px;
	}
}

@media (max-width: 1040px) {
	.about-content-element p {
		font-size: 1.15rem;
		line-height: 1.4;
	}
}

@media (max-width: 1000px) {
	.about-content-element p {
		font-size: 1rem;
		line-height: 1.4;
	}
}
/* @media (max-width: 768px) {
  .about-content-element p {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  .about-content-element h1 {
    font-size: 2rem;
    margin-bottom: 2vh;
  }
} */

@media (max-width: 681px) {
	.about-content-element h1 {
		font-size: 2rem;
		margin-bottom: 1.5vh;
	}
}

@media (max-width: 620px) {
	.about-content-element p {
		font-size: 0.9rem;
	}
}

@media (max-width: 768px) {
	.about {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.about-sidebar {
		width: 100%;
		height: 10%;
		margin: 10px;
	}

	.about-sidebar-content {
		flex-direction: row;
		gap: 10vw;
		width: auto;
		height: 100%;
	}

	.about-sidebar-element {
		width: 100%;
		height: 100%;
		font-size: 0.8rem;
		padding: 0px 5px;
		margin: 0;
	}
	.about-sidebar-element.active {
		font-size: 1.1rem;
		letter-spacing: 5.2px;
		text-shadow: 0px 0px 5px rgb(202 196 196), 0px 0px 8px rgb(112 105 105);
	}

	.about-main {
		width: 120%;
		height: 90vh;
		border-right: none;
	}

	.about-main-content {
		width: 80%;
		gap: 120vh;
	}
	.about-main-heading {
		display: none;
	}

	.about-content-element {
		margin: 0;
		min-height: 62vh;
	}

	.about-social-media-icons {
		flex-direction: row;
		width: auto;
		height: 10vh;
	}
	.about-downarrow {
		position: relative;
		display: block;
		height: auto;
		width: auto;
	}
}
@media (max-width: 546px) {
	.about-content-element p {
		font-size: 0.8rem;
	}
}

@media (max-width: 425px) {
	.about-content-element p {
		font-size: 0.7rem;
	}

	.about-content-element h1 {
		font-size: 1.8rem;
		margin-bottom: 1.5vh;
	}

	.about-sidebar-element {
		font-size: 0.5rem;
	}

	.about-sidebar-element.active {
		font-size: 0.7rem;
	}
	.about-social-media-icons {
		flex-direction: row;
		width: auto;
	}
}
