@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');


.stac_container {
    display: grid;
    place-items: center;
    font-family: 'Poppins', sans-serif;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    grid-gap: 60px;
    padding: 35px 25px;
}

.stac_contentLeft,
.stac_contentRight {
    width: 100%;
}

.stac_contentLeft .stac_row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.stac_contentLeft .stac_row .stac_imgWrapper {
    width: 100%;
    height: 450px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.15);
}

.stac_contentLeft .stac_row .stac_imgWrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
    transition: 0.3s ease;
}

.stac_contentLeft .stac_row .stac_imgWrapper:hover img {
    transform: scale(1.5);
}

.stac_contentLeft .stac_row .stac_imgWrapper:nth-child(odd) {
    transform: translateY(-20px);
}

.stac_contentLeft .stac_row .stac_imgWrapper:nth-child(even) {
    transform: translateY(20px);
}

.stac_contentRight .stac_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.stac_contentRight .stac_content h4 {
    font-size: 22px;
    font-weight: 400;
    color: #f7f7f7;
    font-family: "Bruno Ace",
        "Trebuchet MS",
        "Lucida Sans Unicode",
        "Lucida Grande",
        "Lucida Sans",
        Arial,
        sans-serif;
}

.stac_contentRight .stac_content h2 {
    font-size: 36px;
    color: #ffffff;
    font-family: "Bruno Ace",
        "Trebuchet MS",
        "Lucida Sans Unicode",
        "Lucida Grande",
        "Lucida Sans",
        Arial,
        sans-serif;
}

.stac_contentRight .stac_content p {
    font-size: 16px;
    color: #ffffff;
    line-height: 28px;
    padding-bottom: 10px;
    font-family: cursive;
}

.stac_contentRight .stac_content a {
    display: inline-block;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 13px 30px;
    color: #fff;
    background: #30a8cf;
    border-radius: 8px;
    user-select: none;
}

@media (max-width: 768px) {
    .stac_container {
        grid-template-columns: 1fr;
    }

    .stac_contentLeft .stac_row {
        grid-template-columns: repeat(2, 1fr);
    }

    .stac_contentLeft .stac_row .stac_imgWrapper {
        height: 150px;
    }

    .stac_contentRight .stac_content h4 {
        font-size: 18px;
    }

    .stac_contentRight .stac_content h2 {
        font-size: 30px;
    }
}


























/* Created By : Mohammad Al Hefel */
:root {
    --main-color: #3f51b5;
}

.stac_hero_container {
    padding: 0 8%;
    font-family: "Poppins", sans-serif;
}

.stac_hero_container h2 {
    text-align: center;
    padding-top: 6%;
    margin-bottom: 60px;
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
}

.stac_hero_container h2::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 5px;
    background-color: var(--main-color);
    border-radius: 20px;
}

.stac_hero_row {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr); /* 4 columns, 1 row by default */
}

@media (max-width: 992px) { /* Medium screens (less than 992px) */
    .stac_hero_row {
        grid-template-columns: repeat(2, 1fr); /* 2 columns, 2 rows */
    }
}

@media (max-width: 576px) { /* Small screens (less than 576px) */
    .stac_hero_row {
        grid-template-columns: 1fr; /* 1 column, 4 rows */
    }
}

.stac_hero_row .stac_hero_service {
    padding: 25px 15px;
    background: transparent;
    font-size: 15px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: 0.5s;
}

.stac_hero_row .stac_hero_service:hover {
    color: #fff;
    background-color: var(--main-color);
    transform: scale(1.05);
}

.stac_hero_row .stac_hero_service i {
    color: var(--main-color);
    margin-bottom: 20px;
    font-size: 40px;
    transition: 0.5s;
}

.stac_hero_row .stac_hero_service:hover i {
    color: #fff;
}

.stac_hero_row .stac_hero_service h2 {
    font-weight: 600;
    margin-bottom: 20px;
}































.stac_hero_section_section h2 {
    text-align: center;
    padding-top: 6%;
    margin-bottom: 60px;
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
}

.stac_hero_section_section h2::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 5px;
    background-color: var(--main-color);
    border-radius: 20px;
}



.stac_hero_section_section {
    padding: 20px;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    display: grid;
    place-items: center;
}

.stac_hero_section_row {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 2em;
    justify-content: center;
    align-items: center;
    column-gap: 3em;
}

.stac_hero_section_column {
    /* width: 100%; */
    /* padding: 0 1em 1em 1em; */
    text-align: center;
}

.stac_hero_section_card {
    /* width: 100%;
    height: 100%; */
    padding: 2em 1.5em;
    background: linear-gradient(#ffffff 50%, #014f8d 50%);
    background-size: 100% 200%;
    background-position: 0 2.5%;
    border-radius: 5px;
    box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    transition: 0.5s;
}

.stac_hero_section_card h3 {
    font-size: 20px;
    font-weight: 600;
    color: #1f194c;
    margin: 1em 0;
}

.stac_hero_section_card p {
    color: #575a7b;
    font-size: 15px;
    line-height: 1.6;
    letter-spacing: 0.03em;
}

.stac_hero_section_icon-wrapper {
    background-color: #014f8d;
    position: relative;
    margin: auto;
    font-size: 30px;
    height: 2.5em;
    width: 2.5em;
    color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    transition: 0.5s;
}

.stac_hero_section_card:hover {
    background-position: 0 100%;
}

.stac_hero_section_card:hover .stac_hero_section_icon-wrapper {
    background-color: #ffffff;
    color: #014f8d;
}

.stac_hero_section_card:hover h3 {
    color: #ffffff;
}

.stac_hero_section_card:hover p {
    color: #f0f0f0;
}

@media screen and (min-width: 768px) {
    .stac_hero_section_section {
        padding: 0 2em;
    }

    .stac_hero_section_column {
        flex: 0 50%;
        max-width: 50%;
    }
}

@media screen and (min-width: 992px) {
    .stac_hero_section_section {
        padding: 1em 3em;
    }

    .stac_hero_section_column {
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
}



























@import url('https://fonts.googleapis.com/css?family=Montserrat:600|Open+Sans');




.stac_footer_services-grid a {
    text-decoration: none;
    margin: 1rem 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.8125rem;
    letter-spacing: 1px;
}

.stac_footer_section p {
    font-size: 0.9rem;
    line-height: 1.5;
    text-align: center;
    color: #ffffff;
}

.stac_footer_section {
    margin: 0 auto;
    text-align: center;
    padding: 30px;
    font-family: 'Open Sans', sans-serif;
    color: #999;
}

.stac_footer_section h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 1.825rem;
    margin: 1.3rem 0;
}

.stac_footer_section-lead {
    max-width: 600px;
    margin: 1rem auto 1.5rem;
}

.stac_footer_service a {
    color: #5b7abb;
    display: block;
}

.stac_footer_service h4 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color: #56ceba;
    font-size: 1.3rem;
    margin: 1rem 0 0.6rem;
}

.stac_footer_services-grid {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.stac_footer_service {
    /* background: #fff; */
    margin: 20px;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    -webkit-box-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #e7e7e7;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    /* background-image: url('https://www.toptal.com/designers/subtlepatterns/patterns/geometry2.png'); */
}

.stac_footer_service:hover {
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
}

.stac_footer_service i {
    font-size: 3.45rem;
    margin: 1rem 0;
}

.stac_footer_service1 i,
.stac_footer_service1 h4,
.stac_footer_service1 .stac_footer_cta {
    color: #42b7ca;
}

.stac_footer_service1:hover {
    border: 2px solid #42b7ca;
}

.stac_footer_service2 i,
.stac_footer_service2 h4,
.stac_footer_service2 .stac_footer_cta {
    color: #425fca;
}

.stac_footer_service2:hover {
    border: 2px solid #425fca;
}

.stac_footer_service3 i,
.stac_footer_service3 h4,
.stac_footer_service3 .stac_footer_cta {
    color: #9c42ca;
}

.stac_footer_service3:hover {
    border: 2px solid #9c42ca;
}

.stac_footer_service .stac_footer_cta span {
    font-size: 0.6rem;
}

.stac_footer_service>* {
    flex: 1 1 100%;
}

.stac_footer_service .stac_footer_cta {
    align-self: flex-end;
}

.stac_footer_section h3 {
    text-align: center;
    padding-top: 6%;
    margin-bottom: 60px;
    font-size: 1.8rem;
    font-weight: 600;
    position: relative;
}

.stac_footer_section h3::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 5px;
    background-color: var(--main-color);
    border-radius: 20px;
}

@media all and (max-width: 900px) {
    .stac_footer_services-grid {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }
}