.footer_code {
	position: relative;
	width: 100%;
	background: rgba(0, 0, 0, 0.8);
}

.footer {
	position: relative;
	display: flex;
	justify-content: space-between;
}

.footer-logo {
	position: relative;
	width: 11em;
	height: auto;
	margin: 1vw;
}

.footer .menu {
	position: relative;
	padding: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin: 1px 0;
	list-style: none;
}

.social_icon {
	position: relative;
	padding: 0%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 1px 0;
}

.social-icon__link {
	font-size: 1.5rem;
	color: #fcf6f6;
	margin: 0 9px;
	display: inline-block;
	transition: 0.5s;
}

.social-icon__link:hover {
	transform: translateY(-2px);
	color: rgb(5, 122, 108);
}

.menu div {
	display: flex;
}

.menu__link {
	font-size: 1.3rem;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	color: #f7f4f4;
	margin: 0 9px;
	display: flex;
	transition: 0.5s;
	text-decoration: none;
	font-weight: 300;
	padding: 1vw;
	position: relative;
	margin-bottom: 8%;
}

.menu__link:hover {
	color: rgb(31, 176, 160);
}

.footer-copyright {
	color: #f7f4f4;
	text-align: center;
	font-size: 0.9rem;
	padding-bottom: 1rem;
	margin-top: -1.7rem;
}

.rob {
	margin-bottom: -0.4%;
}

.menu_link {
	display: flex;
	position: relative;
	color: #0087ca;
}

.menu__link::after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 2px;
	bottom: 3px;
	left: 0;
	background-color: rgb(31, 176, 160);
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.menu__link:hover::after {
	transform: scaleX(1);
	transform-origin: bottom left;
}

@media screen and (max-width: 1100px) {
	.menu__link {
		font-size: 1.2rem;
	}

	.social-icon__link {
		font-size: 1.2rem;
		margin: 0 5px;
	}

	.footer-copyright {
		width: 98vw;
	}
}

@media screen and (max-width: 1120px) {
	.menu__link {
		font-size: 1.2rem;
		margin: 0 7px;
	}

	.social-icon__link {
		font-size: 1.2rem;
		margin: 0 7px;
	}
}

@media screen and (max-width: 990px) {
	.menu__link {
		font-size: 1rem;
		margin: 0 6px;
	}

	.social-icon__link {
		font-size: 1rem;
		margin: 0 6px;
	}
}

@media screen and (max-width: 900px) {
	.footer {
		flex-direction: column;
		align-content: center;
		row-gap: 0.5rem;
	}

	.footer-logo {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 10em;
	}

	.menu__link {
		font-size: 1.2rem;
	}

	.social-icon__link {
		font-size: 1.2rem;
	}

	.footer-copyright {
		margin-top: 0.7rem;
		width: 96vw;
	}
}

@media screen and (max-width: 580px) {
	.menu__link {
		font-size: 1.2rem;
	}

	.footer-logo {
		display: block;
		margin-left: auto;
		margin-right: auto;
		width: 8em;
	}

	.footer-copyright {
		width: 93vw;
	}
}

@media screen and (max-width: 305px) {
	.menu__link {
		margin: 0 2px;
	}
}