.blog-container {
	/* background: #150821; */
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-block: 20px;
	text-align: center;
	flex: 1;
	font-family: monospace;
}

.blog-header-container {
	position: relative;
	text-align: center;
}

.blog-header-container b {
	font-size: 3.4rem;
	color: white;
	font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode",
		"Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.blog-header-container button {
	position: absolute;
	top: calc(50% - 1.25em);
	left: calc(100% + 15vw);
	font-size: 1.1rem;
	width: max-content;
	padding: 0.7rem;
	border-radius: 10px;
	font-family: "Rubik", sans-serif;
	background: black;
	box-shadow: 0 0 15px rgb(0, 168, 235);
	color: #ccc;
	border-style: solid;
	border-width: 0.5px;
	border-color: #00a8eb;
	transition: 0.5s ease;
}

.blog-header-container button:hover {
	scale: 1.05;
	border-color: rgb(157, 59, 255);
	box-shadow: 0 0 20px rgb(157, 59, 255);
	cursor: pointer;
}

@media (max-width: 1200px) {
	.blog-header-container button {
		left: calc(100% + 4vw);
	}
}

@media (max-width: 850px) {
	.blog-header-container {
		display: flex;
		flex-direction: column;
		row-gap: 1rem;
		align-items: center;
	}

	.blog-header-container button {
		position: static;
	}

	.blog-header-container b {
		font-size: 2.7rem;
	}
}

@media (max-width: 460px) {
	.blog-header-container b {
		font-size: 2.5rem;
	}
}

/* @media screen and (max-width: 310px) {
  .blog-header-container b {
    font-size: 1.7rem;
  }
} */

@media (max-width: 768px) {
	.blog-header {
		font-size: 1.5rem;
		line-height: 2.5rem;
	}
}

.blog-content {
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
	justify-content: space-between;
}

.blog-sidebar {
	padding: 15px;
	padding-right: 45px;
	width: 30%;
	height: fit-content;
}

@media (max-width: 768px) {
	.blog-content {
		flex-direction: column;
		align-items: center;
	}

	.blog-top-reads h1 {
		margin: 2rem;
	}

	.blog-sidebar {
		width: 90%;
		padding: 15px;
	}
}

.blog-top-reads-posts hr {
	margin: 5px 20px;
	border: 0;
	border-top: 2px solid #646464;
}

.blog-tag-list {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 90%;
	margin-inline: 5%;
	transition: 0.7s;
	margin-bottom: 40px;
}

@media (min-width: 768px) {
	.blog-sidebar {
		position: sticky;
		/* top: 10px; */
	}
}

@media (max-width: 768px) {
	.blog-tag-list {
		width: -webkit-fill-available;
		margin-bottom: 0;
	}
}

.blog-tag-list h1,
.blog-sidebar h1 {
	font-size: 1.45rem;
	margin: 0.5rem;
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-weight: 600;
}

.blog-top-reads {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
	font-size: 0.9rem;
}

.blog-tag-list .input-wrap {
	width: 100%;
	position: relative;
}

.blog-tag-list .input-wrap i {
	position: absolute;
	font-size: 16px;
	right: 12px;
	top: 7px;
}

.blog-tag-list input {
	background: transparent;
	border: 1px solid white;
	border-radius: 18px;
	padding: 7px 12px;
	font-size: 1em;
	width: 100%;
	color: white;
	box-sizing: border-box;
}

.blog-tag-list input:focus {
	outline: none;
}

.blog-tags-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.blog-tag-button {
	margin: 0.2rem;
	background-color: rgb(220, 220, 220);
	color: black;
	font-size: 16px;
	padding: 5.5px 12px;
	border: 1px solid whitesmoke;
	cursor: pointer;
	border-radius: 50px;
	text-align: center;
	transition: 0.4s ease-out;
	/* -webkit-appearance: button; */
	text-transform: none;
	overflow: visible;
	text-decoration: none;
	font-family: monospace;
}

.blog-tag-button span {
	color: rgb(84, 84, 84);
	margin-left: 0.3em;
}

/* .blog-active-tag span {
  color: rgb(49, 66, 87);
} */

.blog-tag-button:hover {
	/* transform: scale(1.08); */
	background-color: rgb(192, 233, 224);
}

.blog-tag-list .blog-active-tag {
	/* background-color: rgb(16, 165, 140); */
	background-color: rgb(199, 250, 239);
	border: 1px solid rgb(16, 165, 140);
	box-shadow: 0 0 10px rgb(16, 165, 140), 0 0 15px rgba(255, 255, 255, 0.43);
}

.blog-tag-list .blog-active-tag:hover {
	background-color: whitesmoke;
}

@media (max-width: 450px) {
	.blog-tag-list h1 {
		font-size: 1.5rem;
	}

	.blog-tag-list > br:nth-child(2) {
		display: none;
	}

	.blog-tag-list input {
		font-size: 13px;
	}

	.blog-tag-list .blog-tag-button {
		font-size: 13px;
		padding: 3px 6px;
	}

	.blog-tag-button:hover {
		transform: scale(1);
	}
}
