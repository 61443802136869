.blog-post-page {
  display: flex;
  /* width: 92.5%;
  margin: 30px auto; */
  width: 100%;
  box-sizing: border-box;
  padding: 30px 5%;
  gap: 5%;
  font-family: "Rubik", sans-serif;
  background-color: rgb(243, 243, 243);
}

.blog-post-container {
  width: 70%;
  height: fit-content;
  padding: 0.75rem 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* border: 1px inset #434343; */
  /* border-left: 1px solid #eaeaea; */
  /* border-right: 1px solid #eaeaea; */
  /* background: white; */
  border-radius: 13px;
  color: black;
  /* padding-left: 2em;
  padding-right: 1.2rem; */
  font-size: 1rem;
}

.blog-post-container.editable {
  width: 100%;
}

#viewer+.ck>.ck-editor__main>.ck-editor__editable {
  border: none !important;
  background-color: rgb(243, 243, 243);
}

.ck>.ck-editor__main * {
  max-width: 100% !important;
  height: auto !important;
  background: transparent !important;
}

.blog-post-container h1 {
  color: black;
  font-family: monospace;
}

.blog-post-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 5px;
  padding: 20px;
  padding-bottom: 10px;
}

.blog-post-container .blog-post-footer a {
  text-decoration: none;
  background-color: #3c52ff;
  color: white;
  /* border: 1px solid white; */
  padding: 7px;
  border-radius: 5px;
  transition: all 0.5s;
}

.blog-post-container .blog-post-footer a:hover {
  transform: scale(1.1);
}

.blog-post-sidebar {
  width: 27.5%;
  height: fit-content;
  position: sticky;
  color: black;
  top: 0;
}

.blog-post-sidebar h3 {
  text-align: center;
  padding: 10px 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 1.4em;
}

.blog-post-sidebar>h3:not(:first-of-type) {
  margin-top: 25px;
}

.blog-post-sidebar>hr {
  border: 1px solid rgb(49, 49, 49);
}

.blog-post-related {

  padding: 10px 17px;
  display: flex;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;

}

.blog-post-related img {
  width: 30%;
  height: auto;
  max-height: 120px;
  border-radius: 10px;
  border: 1px solid rgb(61, 61, 61);
  cursor: pointer;
}

.blog-post-related p {
  padding-block: 5px;
}

.blog-post-related p.date {
  color: rgb(89, 89, 89);
}

.blog-post-related p:not(.date) {
  cursor: pointer;
}

.blog-post-related-container hr {
  width: calc(100% - 80px);
  margin-inline: auto;
  border: 1px solid rgb(127, 127, 127);
}

.blog-post-sidebar>.blog-tags-container {
  margin: 10px auto;
  width: 95%;
}

@media (max-width: 1100px) {
  .blog-post-sidebar {
    width: 32.5%;
  }

  .blog-post-container {
    width: 65%;
  }
}

@media (max-width: 850px) {
  .blog-post-sidebar {
    width: 37%;
    font-size: 0.85em;
  }

  .blog-post-container {
    width: 61%;
  }
}

@media (max-width: 750px) {
  .blog-post-page {
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .blog-post-container {
    width: 100%;
  }

  .blog-post-sidebar {
    width: 100%;
  }

  .blog-post-related-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .blog-post-related-container hr:nth-of-type(odd) {
    display: none;
  }

  .blog-post-related {
    width: 45%;
  }
}

@media (max-width: 550px) {
  .blog-post-related-container hr:nth-of-type(odd) {
    display: block;
  }

  .blog-post-related {
    width: 100%;
  }
}