/* ==========================Font========================== */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.publication_filtered-container {
    width: 100%;
    overflow-x: hidden;
}

.publication_filter-dropdown {
    margin-bottom: 20px;
    text-align: center;
}

.publication_filter-dropdown .publication_filter-text {
    display: block;
    font-size: 18px;
    color: #2cdccd;
    margin-bottom: 10px;
}

.publication_filter-dropdown select {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: #1e2a38;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.3s ease, color 0.3s ease;
}

.publication_filter-dropdown select:hover {
    background: #2c3e50;
    color: #ffddc1;
}

.publication_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 auto;
}

.publication_card {
    position: relative;
    height: 450px;
    width: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #000;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 30px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.publication_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

#publication_paragraph {
    font-family: "Rubik", sans-serif;
    font-weight: bolder;
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 1.5;
    margin: 0;
    color: #ffffff;
}

#publication_keywords-white {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 12px;
    color: rgb(242, 227, 15);
}


.publication_card:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 66%);
    pointer-events: none;
}

.publication_card.publication_skew::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: #fff;
    transform: skew(2deg, 2deg);
    z-index: -1;
}


.publication_card .publication_content {
    position: relative;
    padding: 20px;
    transform: translateY(40px);
    z-index: 100;
}

.publication_card .publication_content h2 {
    position: absolute;
    top: -60px;
    right: 20px;
    margin: 0;
    padding: 0;
    font-size: 10em;
    color: rgba(255, 255, 255, .2);
    transition: .5s;
}

.publication_card:hover .publication_content h2 {
    top: -170px;
}

.publication_card .publication_content h3 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
    color: #fff;
}

.publication_card .publication_content p {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #fff;
    font-family: "Rubik", sans-serif;
}

.publication_card .publication_content a {
    margin: 20px 0 0;
    position: relative;
    padding: 10px 20px;
    text-decoration: none;
    border: 1px solid #fff;
    display: inline-block;
    color: #fff;
    transition: .5s;
    transform: translateY(-100px);
    opacity: 0;
    visibility: none;
}

.publication_card .publication_content a {
    /* transform: translateY(50px); */
    top: 130px;
    opacity: 1;
    visibility: visible;
    font-family: "Rubik", sans-serif;
}

.publication_card .publication_content a:hover {
    background: #fff;
    color: #000;
}

@keyframes publication_colorChange {
    0% {
        color: #fff;
    }

    50% {
        color: #b1b493;
    }

    80% {
        color: #4f8a8b;
    }

    100% {
        color: #fff;
    }
}

/* =======================Responsive Breakpoint ========================== */

/* @media only screen and (max-width: 992px) {
    .publication_container {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .publication_container .publication_card {
        width: 450px;
    }
} */

.publication_dropdown-complete {
    display: flex;
    justify-content: center;
    gap: 30px;
    width: 94vw;
    text-align: center;
    margin-left: 0;
}

.publication_filter-text {
    font-size: 2rem !important;
}