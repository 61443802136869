.publication_home-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.publication_lr-about-complete {
    display: "flex";
    padding: 2px 60px 2px 30px;

}



.publication_lr-pic {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
    row-gap: 1rem;
}

.publication_lr-pic-left {
    transform: scale(0.7);
    transform: scaleX(300px);
    opacity: 0.5;
}

.publication_lr-pic-right {
    transform: scale(0.7);
    transform: scaleX(300px);
    opacity: 0.5;
}

.publication_about_literature_review {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1rem;
    row-gap: 1rem;
    text-align: justify;
    /* width: 50vw; */
    padding: 0px;
    /* width: ; */
}

.publication_main-title {
    font-size: 2rem;
    font-weight: 700;
    color: #cdcdcd;
    margin-bottom: 1rem;
    text-align: center;
}

.publication_header-complete {
    padding-top: 30px;
}

.publication_lr-quote {
    font-size: 1.3rem;
    font-weight: 500;
    color: #bbfbfe;
    margin-bottom: 1rem;
    text-align: center;
    font-style: italic;
    padding: 20px 100px;
}

.publication_waviy {
    position: relative;
}

.publication_main-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0;
    font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.publication_waviy span {
    position: relative;
    display: inline-block;
    font-size: 60px;
    /* color: #15f3ff; */
    color: rgb(210, 229, 250);
    text-transform: uppercase;
}

.publication_lr-title {
    text-align: center;
    font-size: 3rem;
    top: 0;
    font-family: "Bruno Ace", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}

.publication_i1 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 0s;
}

.publication_i2 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 0.2s;
}

.publication_i3 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 0.4s;
}

.publication_i4 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 0.6s;
}

.publication_i5 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 0.8s;
}

.publication_i6 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 1s;
}

.publication_i7 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 1.2s;
}

.publication_i8 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 1.4s;
}

.publication_i9 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 1.6s;
}

.publication_i10 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 1.8s;
}

.publication_i11 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 2s;
}

.publication_i12 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 2.2s;
}

.publication_i13 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 2.4s;
}

.publication_i14 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 2.6s;
}

.publication_i15 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 2.8s;
}

.publication_i16 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 3s;
}

.publication_i17 {
    animation-duration: 2s;
    animation-name: flip;
    animation-delay: 3.2s;
}

@keyframes flip {

    6.5%,
    7% {
        transform: rotateY(180deg);
    }
}

.publication_carousel-container {
    position: relative;
    display: block;
    width: 500px;
    height: 300px;
    overflow: hidden;
    border-radius: 5px;
    justify-content: center;
    left: 10%;
    top: 5%;
}

.publication_carousel-container:before,
.publication_carousel-container:after {
    content: '⥪';
    position: absolute;
    top: 50%;
    left: 1rem;
    z-index: 2;
    width: 2rem;
    height: 2rem;
    background: dodgerblue;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.publication_carousel-container:after {
    content: '⥭';
    left: auto;
    right: 1rem;
}


.publication_lr-about {
    padding: 0 50px 0 50px;
    font-size: 20px;
    font-family: cursive;
}

@media (max-width: 920px) {
    .publication_lr-about {
        padding: 0 20px 0 20px;
        font-size: 18px;
        font-family: cursive;
    }

    .publication_main-inner {
        scale: 0.8;
    }
}

@media (max-width: 768px) {
    .publication_lr-about-complete {
        display: "flex";
        flex-direction: column;
        padding: 2px 30px 2px 30px;

    }

    .publication_main-inner {
        scale: 0.8;
    }
}

@media (max-width: 638px) {
    .publication_main-inner {
        scale: 0.7;
    }

    .publication_lr-quote {
        font-size: 1rem;
        font-weight: 500;
        color: #bbfbfe;
        margin-bottom: 1rem;
        text-align: center;
        font-style: italic;
        padding: 5px 10px;
    }

    .publication_lr-about {
        padding: 0 15px 0 15px;
        font-size: 15px;
        font-family: cursive;
    }
}

@media (max-width: 492px) {
    .publication_main-inner {
        scale: 0.4;
    }

    .publication_container {
        scale: 0.95;
    }

    .publication_lr-about-complete {
        display: "flex";
        padding: 0px;
        scale: 0.95;

    }
}