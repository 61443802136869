@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

:root {
  --space-root: 1rem;
  --space-xs: calc(var(--space-root) / 2);
  --space-s: calc(var(--space-root) / 1.5);
  --space-m: var(--space-root);
  --space-l: calc(var(--space-root) * 1.5);
  --space-xl: calc(var(--space-root) * 2);
  --color-primary: rgba(100, 149, 237, 1);
  /* --color-primary: mediumslateblue; */
  --color-secondary: black;
  --color-tertiary: black;
  --base-border-radius: 0.25rem;
  --ease: cubic-bezier(0.075, 0.82, 0.165, 1);
  --duration: 350ms;
  --font-family: "Roboto", sans-serif;
  --font-size: 1.25rem;
}

/* @mixin label-styles {
  font-size: calc(var(--font-size) / 1.65);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.065rem;
} */

.login-container * {
  box-sizing: border-box;
  color: black;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0;
  height: 70vh;
  font-family: var(--font-family);
  line-height: 1.2;
  /* background-color: var(--color-tertiary); */
}

.login-form a {
  color: rgba(16, 72, 144, 1);
}

.login-form a:focus {
  color: var(--color-secondary);
}

.login-form h2 {
  font-weight: 700;
  font-size: 30px;
  color: black;
}

.login-form {
  position: relative;
  width: 500px;
  margin: 0 auto;
  /* transform: skewY(-5deg) translateY(10%) scale(0.94);
  transition: box-shadow var(--duration) var(--ease),
    transform var(--duration) var(--ease); */
}

/* .login-form:before,
.login-form:after {
  content: "";
  position: absolute;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.8);
  width: 25%;
  height: 100%;
  transition: background-color var(--duration) var(--ease),
    transform var(--duration) var(--ease);
}

.login-form:before {
  top: 0;
  right: calc(100% - 1px);
  transform-origin: 100% 100%;
  transform: skewY(-35deg) scaleX(-1);
  z-index: -1;
}

.login-form:after {
  top: 0;
  left: calc(100% - 1px);
  transform-origin: 0 0;
transform: skewY(-35deg) scaleX(-1);
  z-index: 2;
} */

.login-form:hover,
.login-form:focus-within {
  transform: scale(1.0001);
  box-shadow: 0 1rem 3rem rgba(black, 0.1);
}

/* .login-form:focus-within:before,
.login-form:focus-within:after,
.login-form:hover:after,
.login-form:hover:before {
  background-color: black;
  transform: skewY(0);
} */

.form-inner {
  padding: 20px;
  background-color: whitesmoke;
  z-index: 1;

  > * + * {
    margin-top: 40px;
  }
}

.input-wrapper {
  margin-top: 20px;
}

.input-wrapper:focus-within label {
  color: black;
}

.input-wrapper:focus-within .icon {
  background-color: var(--color-secondary);
}

.input-wrapper:focus-within input {
  border-color: var(--color-secondary);
}

.input-group {
  position: relative;
}

.input-group input {
  border-radius: var(--base-border-radius);
  padding-left: 2000px;
}

.input-group .icon {
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  border-top-left-radius: var(--base-border-radius);
  border-bottom-left-radius: var(--base-border-radius);
  pointer-events: none;
}

.login-form label {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  /* letter-spacing: 0.065rem; */
  display: block;
  margin-bottom: 5px;
  color: rgba(16, 72, 144, 1);
  transition: color 0.15s;
}

.login-form .icon {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  padding: 15px;
  background-color: var(--color-primary);
  background-color: rgba(16, 72, 144, 1);
  transition: background-color 0.15s;
}

.icon svg {
  height: 30px;
  width: 30px;
  fill: white;
  pointer-events: none;
  user-select: none;
  transition: transform var(--duration) var(--ease);
}

.login-form input {
  flex: 1 1 0;
  width: 90%;
  transform: translateX(50px);
  outline: none;
  padding: 15px;
  font-size: 15px;
  font-family: var(--font-family);
  color: var(--color-secondary);
  border: 2px solid var(--color-primary);
}

.login-form input:focus {
  color: var(--color-primary);
}

.btn-group {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > * + * {
    margin-left: var(--space-s);
  }
}

.btn {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: none;
  padding: 10px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: var(--base-border-radius);
}

.btn-primary {
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.065rem;
  background-color: rgba(16, 72, 144, 1);
  border-color: rgba(16, 72, 144, 1);
  color: white;
  transform: translateX(-10px);
  transition: all 0.15s;
}

.btn-primary:hover {
  background-color: rgb(12, 56, 113);
  border-color: black;
  color: white;
}

.btn-text {
  font-size: 15px;
  padding: 0;
}

@media only screen and (max-width: 950px) {
  .login-form {
    width: 448px;
  }

  .login-form h2 {
    font-size: 25px;
  }

  .login-form label {
    font-size: 14px;
  }

  .login-form input {
    padding: 11px;
  }

  .icon svg {
    height: 27px;
    width: 27px;
  }

  .input-group .icon {
    left: -2px;
    padding: 13px;
  }

  .btn-primary {
    transform: translateX(-3px);
  }

  .btn-primary {
    font-size: 13px;
  }

  .btn-text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  .login-form {
    width: 400px;
  }

  .login-form h2 {
    font-size: 23px;
  }

  .login-form label {
    font-size: 13px;
  }

  .login-form input {
    padding: 9px;
  }

  .icon svg {
    height: 27px;
    width: 27px;
  }

  .input-group .icon {
    left: -2px;
    padding: 13px;
  }

  .btn-primary {
    transform: translateX(-3px);
  }

  .btn-primary {
    font-size: 11px;
  }

  .btn-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 425px) {
  .login-form {
    width: 350px;
  }

  .login-form h2 {
    font-size: 19px;
  }

  .login-form label {
    font-size: 11px;
  }

  .login-form input {
    padding: 8px;
  }

  .icon svg {
    height: 23px;
    width: 27px;
  }

  .input-group .icon {
    left: -2px;
    padding: 13px;
  }

  .btn-primary {
    transform: translateX(-3px);
  }

  .btn-primary {
    font-size: 9px;
  }

  .btn-text {
    font-size: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .login-form {
    width: 300px;
  }

  .login-form h2 {
    font-size: 19px;
  }

  .login-form label {
    font-size: 11px;
  }

  .login-form input {
    padding: 8px;
  }

  .icon svg {
    height: 23px;
    width: 27px;
  }

  .input-group .icon {
    left: -2px;
    padding: 13px;
  }

  .btn-primary {
    transform: translateX(-3px);
  }

  .btn-primary {
    font-size: 9px;
  }

  .btn-text {
    font-size: 12px;
  }
}
