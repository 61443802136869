@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

.blog-cards-list {
  z-index: 0;
  margin: 15px 5%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-family: "Rubik", sans-serif;
}

.blog-cards-list h1 {
  padding-right: 20vw;
}

.blog-cards-list h1 span {
  color: rgb(16, 165, 140);
  font-family: monospace;
}

@media (min-width: 769px) {
  .blog-cards-list {
    width: 74%;
  }
}

@media (max-width: 768px) {
  .blog-cards-list {
    margin: 10px;
  }
}

.blog-cards-list h1 {
  font-size: 1.7rem;
  margin: 0.5rem;
}

.blog-post-box {
  color: white;
  padding: 20px 30px;
  width: 100%;
  border-radius: 5px;
  /* background-color: #eaddff;
  box-shadow: 5px 5px 30px 7px rgba(0, 0, 0, 0.534); */
  transition: 0.4s;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  font-size: 1rem;
}

.blog-cards-list > hr:not(:last-of-type) {
  margin: 0 auto;
  width: 85%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.358);
  border: none;
}

/* .blog-post-box:hover{
  scale: 1.03;
} */

.blog-post-box-glass {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  font-size: 1rem;
}

@media (max-width: 768px) {
  .blog-post-box {
    padding: 10px 20px;
    max-width: 90%;
    flex-direction: column;
  }
  .blog-cards-list h1 {
    padding-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .blog-post-box-glass {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 350px) {
  .blog-post-box-glass {
    font-size: 0.8rem;
  }
}

/* .blog-post-box:hover {
  box-shadow: 5px 5px 30px 15px rgba(255, 255, 255, 0.074);
  transition: 0.4s ease-out;
} */

.blog-post-box img {
  margin: auto;
  max-width: 25%;
  max-height: 200px;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .blog-post-box img {
    max-width: 70%;
  }
}

@media (max-width: 400px) {
  .blog-post-box img {
    max-height: 150px;
  }
}

.blog-post-title {
  font-size: 2em;
  margin-bottom: 0.5em;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  cursor: pointer;
}

.blog-cards-list .blog-post-title:hover {
  color: #8cf2ff;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-decoration-color: rgb(128, 223, 207);
}

.blog-post-box-glass .blog-post-title {
  font-size: 1.7em;
}

.blog-post-desc {
  font-size: 1em;
  margin-bottom: 0.5em;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 0;
  cursor: pointer;
  text-align: left;
}

.blog-post-tags {
  font-size: 1em;
  margin-bottom: 0.5em;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 1.3em;
  display: flex;
  row-gap: 5px;
}

.blog-post-box .blog-post-tags {
  margin: 0.7em 0;
}

.blog-post-tag {
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: rgb(87, 87, 87);
  /* color: black; */
  font-family: monospace;
}

.blog-post-box .blog-post-tag {
  border-radius: 20px;
}

.blog-card-footer {
  font-size: 1em;
  margin-bottom: 0.5em;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 0;

  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 10px;
}

.blog-card-footer i {
  padding-right: 0.5em;
}
.blog-card-footer i.fa-circle {
  padding-left: 0.5em;
  font-size: 0.2em;
}

@media (max-width: 760px) {
  .blog-card-footer {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .blog-card-footer i.fa-circle {
    display: none;
  }
}

/* .blog-post-button {
  background-color: #20ab3a;
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  transition: 0.4s ease-out;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  text-decoration: none;
}

.blog-post-button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
} */

.blog-post-button {
  padding: 0.5em 1em;
  padding-right: 0;
  transition: 0.7s ease;
  width: fit-content;
  color: white;
  color: #8cf2ff;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  z-index: 0;
  font-size: 1em;
  overflow: hidden;
  text-decoration: none;
}

.blog-post-button i {
  margin-left: 0.5em;
  opacity: 0;
  transition-duration: 0.5s;
  font-size: 0.9em;
  color: #00d9ff;
}

.blog-post-button:hover {
  color: #00d9ff;
  margin-right: 0.5em;
}

.blog-post-button:hover i {
  opacity: 1;
}
