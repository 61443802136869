.mainav {
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: 10;
  top: 0;
  width: 100%;
  height: 7rem;
  position: -webkit-sticky;
  position: sticky;
  transition: top 0.5s;
}

.menunav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
}

.nav-responsive {
  list-style-type: none;
  overflow: hidden;
  color: white;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  text-align: center;
  float: right;
  text-align: center;
  justify-content: center;

  transition: all 0.5s ease;
}

.menunav li {
  margin-right: 1.5rem;
}

.menunav li a {
  color: white;
  text-decoration: none;
  text-align: center;
  display: inline;
  align-items: center;
  letter-spacing: 0.1rem;
  transition: 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  font-weight: bolder;
  font-size: 1.2rem;
}

.mainav ul li :hover {
  color: rgb(16, 165, 140);
  transform: translateY(-0.25em);
}

.active-nav a {
  border-bottom: 0.2rem solid rgb(16, 165, 140);
}

@media screen and (max-width: 1024px) {
  .menunav li {
    margin-right: 1.2rem;
  }

  .menunav li a {
    color: rgb(170, 170, 170);
  }
}

@media screen and (max-width: 768px) {
  .menunav a:not(:first-child) {
    display: none;
  }

  .menunav a.icon {
    float: right;
    display: block;
  }

  .menunav {
    flex-wrap: wrap;
  }

  .nav-static {
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    border-bottom: 0.1rem solid rgb(64, 64, 64);
    /* keep this */
  }

  .nav_logo {
    height: 100%;
  }

  .nav_logo img {
    height: 100%;
    width: auto;
  }

  .barHolder {
    height: 50px;
    width: 50px;
    position: relative;
    right: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .barHolder:hover {
    cursor: pointer;
  }

  .bar {
    height: 0.2rem;
    width: 2rem;
    background-color: white;
    display: block;
    margin-bottom: 0.5rem;
  }

  .responsive-bars .bar1 {
    transform: rotate(45deg);
    position: relative;
    top: 0.7rem;
  }

  .responsive-bars .bar2 {
    opacity: 0;
  }

  .responsive-bars .bar3 {
    transform: rotate(-45deg);
    position: relative;
    top: -0.7rem;
  }

  .menunav>.nav-static>a {
    display: inline;
    margin-right: 5%;
  }

  .menunav li {
    width: 100%;
    height: 3rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mainav ul li :hover {
    transform: none;
  }

  .active-nav a {
    border-bottom: 0.1rem solid rgb(16, 165, 140);
  }

  .nav-responsive {
    display: none;
  }

  .drop-down-nav {
    display: flex;
    flex-direction: column;
    width: 100%;

    position: absolute;
    top: 7.1rem;
    height: unset;
    background: rgba(0, 0, 0, 0.8);
  }
}