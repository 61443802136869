.loader-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex: 1;
	background-color: black;
}

.loader {
	border: 4px solid #f3f3f3; /* Light grey */
	border-top: 4px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spinner 1s linear infinite; /* Animation */
}

.loader-text {
	color: #f3f3f3; /* Light grey */
	font-size: 1.5rem;
	margin-top: 16px;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
