.updates-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  border-style: solid;
  border-width: 200px;
  border-color: white;
  background-color: rgb(29, 27, 27);
  color: #ccc;
  border: 1px solid #222d2f;
  overflow: hidden;
  transition-duration: 1s;
  border-radius: 7px;
  z-index: 3;
}

.updates-card-new {
  animation: new-update-card-appear 1.5s linear;
}
@keyframes new-update-card-appear {
  0% {
    max-height: 0;
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    max-height: 1000px;
  }
}

.updates-card:hover {
  scale: 1.04;
}

.updates-card-img-container {
  width: 100%;
  height: fit-content;
  overflow: hidden;
}

.updates-card-img-container img {
  transition-duration: 1s;
  width: 100%;
}

.updates-card:hover .updates-card-img-container img {
  scale: 1.1;
}

.updates-card-dummy::before {
  content: "";
  position: absolute;
  top: -58px;
  margin-left: -50px;
  height: 0;
  line-height: 0;
  border-top: 60px solid transparent;
  border-left: 600px solid rgb(29, 27, 27);
  border-right: none;
}

.updates-card-items-container {
  width: 90%;
  position: relative;
}

.updates-card-description-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: flex-start;
  font-size: 0.9em;
}

.updates-card-title-container {
  font-size: 1.6em;
}

.updates-card-content-container {
  color: rgb(135, 135, 135);
}

.updates-card-date-container {
  display: flex;
  margin: 0 auto;
  width: 80%;
  justify-content: flex-end;
}

.updates-card-button {
  position: absolute;
  padding: 0.5em 1em;
  padding-right: 0;
  margin-left: 1em;
  width: fit-content;
  cursor: pointer;
  color: white;
  color: #8cf2ff;
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  z-index: 0;
  font-size: 1em;
  overflow: hidden;
  transition: 0.7s ease;
  bottom: -3em;
}

.updates-card-button i {
  margin-left: 0.5em;
  opacity: 0;
  transition-duration: 0.5s;
  font-size: 0.9em;
  color: #00d9ff;
}

.updates-card-button:hover {
  margin-left: 0.5em;
  color: #00d9ff;
}

.updates-card-button:hover i {
  opacity: 1;
}

.updates-card:hover .updates-card-button {
  bottom: 1.3em;
}

/* 
.updates-card-button span {
  position: absolute;
  display: block;
}

.updates-card-button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: update-card-btn-anim1 1s linear infinite;
}
@keyframes update-card-btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.updates-card-button span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: update-card-btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}
@keyframes update-card-btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.updates-card-button span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: update-card-btn-anim3 1s linear infinite;
  animation-delay: 0.5s;
}
@keyframes update-card-btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.updates-card-button span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: update-card-btn-anim4 1s linear infinite;
  animation-delay: 0.75s;
}
@keyframes update-card-btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
} */

@media screen and (max-width: 1600px) {
  .updates-card {
    font-size: 1.05rem;
  }
}

@media screen and (max-width: 1300px) {
  .updates-card {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 1070px) {
  .updates-card {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 950px) {
  .updates-card {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 850px) {
  .updates-card {
    font-size: 0.95rem;
  }
  .updates-card-button {
    bottom: 1.3em;
  }
}

@media screen and (max-width: 600px) {
  .updates-card {
    font-size: 1rem;
  }
}

@media screen and (max-width: 550px) {
  .updates-card {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 410px) {
  .updates-card {
    font-size: 0.8rem;
  }
}
