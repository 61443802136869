.latest-form {
  background: linear-gradient(
    to bottom,
    rgba(29, 27, 27, 1),
    rgba(29, 27, 27, 0.8),
    rgba(29, 27, 27, 0.9)
  );
  color: white;
  font-family: "Rubik", monospace;
  width: 35%;
  margin: 2rem auto;
  padding: 1.5em 3.5em;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  row-gap: 1.8em;
  align-items: center;
}

.latest-form-title {
  font-size: 2.7em;
}

.latest-form-input-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.latest-form-input-label {
  font-size: 1.4em;
}

.latest-form input,
.latest-form textarea {
  width: 69%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  font-size: 1.1em;
  font-family: "Rubik", monospace;
}

.latest-form td textarea {
  min-height: 5em;
}

.latest-form .latest-form-date {
  font-family: monospace;
  font-size: 1.15em;
}

.latest-form-footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.latest-form-button {
  color: black;
  font-size: 1.4em;
  text-decoration: none;
  border-radius: 10px;
  border: 1px solid rgb(0, 0, 0);
  padding: 0.5em 0.75em;
  transition-duration: 0.5s;
  font-family: monospace;
}

.latest-form-button:nth-child(1) {
  background-color: rgb(255, 152, 152);
}

.latest-form-button:nth-child(2) {
  background-color: rgb(143, 255, 163);
}

.latest-form-button:nth-child(1):hover {
  background-color: rgb(255, 88, 88);
}

.latest-form-button:nth-child(2):hover {
  background-color: rgb(77, 255, 109);
}

@media screen and (max-width: 1200px) {
  .latest-form {
    width: 50%;
  }
}

@media screen and (max-width: 850px) {
  .latest-form {
    width: 50%;
  }

  .latest-form-input-container {
    flex-direction: column;
    row-gap: 1em;
    align-items: flex-start;
  }

  .latest-form-input-label {
    padding-left: 0.5em;
  }

  .latest-form input,
  textarea {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .latest-form {
    width: 60%;
    padding: 1.5em 2em;
    font-size: 0.88rem;
  }
}

@media screen and (max-width: 360px) {
  .latest-form {
    width: 75%;
  }
}
