@font-face {
  font-family: Doodle-Font1;
  src: url("../../public/fonts/doodle-font1.otf") format("opentype");
}

@font-face {
  font-family: Doodle-Font2;
  src: url("../../public/fonts/doodle-font2.otf") format("opentype");
}

.landingpage-section1 {
  position: relative;
  overflow: hidden;
}

.landingpage-section1-main-container {
  height: 100vh;
  min-height: 782px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Doodle-font1;
  position: relative;
}

.landingpage-section1-title {
  width: 800px;
  text-align: center;
  font-size: 70px;
  position: relative;
  bottom: 30px;
  z-index: 5;
}

.landingpage-section1-byline {
  text-align: center;
  font-size: 30px;
  font-family: Doodle-Font2;
}

.landingpage-section1-astronaut {
  position: absolute;
  height: 450px;
  bottom: 30px;
  left: 30px;
  animation-name: landingpage-section1-astronaut, landingpage-section1-hover;
  animation-duration: 4s, 7s;
  animation-delay: 0s, 4s;
  animation-iteration-count: 1, infinite;
  rotate: -20deg;
  z-index: 5;
}

.landingpage-section1-asteroid {
  position: absolute;
  height: 200px;
  bottom: 100px;
  left: 200px;
  animation-name: landingpage-rotate;
  animation-iteration-count: infinite;
  animation-duration: 6s;
  animation-timing-function: linear;
  z-index: 5;
}

.landingpage-section1-ufo {
  position: absolute;
  height: 150px;
  top: 60px;
  left: 375px;
  rotate: 10deg;
  animation: landingpage-ufo;
  animation-duration: 2s;
  z-index: 5;
}

.landingpage-section1-saturn {
  position: absolute;
  height: 250px;
  top: 113px;
  right: 150px;
  animation-name: landingpage-saturn;
  rotate: 0deg;
  animation-duration: 2s;
  z-index: 5;
}

.landingpage-section1-telescope {
  position: absolute;
  height: 250px;
  bottom: -25px;
  right: 300px;
  animation-name: landingpage-telescope;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  z-index: 5;
}

/* .landingpage-section1-rocket{
    position: absolute;
    height: 200px;
    left: 10px;
    bottom: -250px;
    rotate: -50deg;
    z-index: 5;
    animation-name: landingpage-rocket;
    animation-duration: 5s;
    animation-delay: 5s;
    animation-iteration-count: infinite;
} */

.landingpage-section1-blob1 {
  height: 500px;
  width: 500px;
  position: absolute;
  top: -150px;
  left: -150px;
  z-index: 0;
}

.landingpage-section1-blob2 {
  height: 500px;
  width: 500px;
  position: absolute;
  bottom: -150px;
  right: -150px;
  z-index: 0;
}

.landingpage-section1-blob3 {
  height: 280px;
  width: 280px;
  position: absolute;
  bottom: 60px;
  left: 130px;
  z-index: 0;
}

@keyframes landingpage-section1-astronaut {
  0% {
    height: 550px;
    bottom: 120px;
    right: 120px;
    rotate: 10deg;
  }
  100% {
    height: 450px;
    bottom: 30px;
    left: 30px;
    rotate: -20deg;
  }
}

@keyframes landingpage-section1-hover {
  0% {
    scale: 1;
    rotate: -20deg;
  }
  50% {
    scale: 1.05;
    rotate: 10deg;
  }
  100% {
    scale: 1;
    rotate: -20deg;
  }
}

@keyframes landingpage-rocket {
  0% {
    bottom: -250px;
  }

  100% {
    bottom: 900px;
  }
}

@keyframes landingpage-rotate {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes landingpage-ufo {
  0% {
    top: 100px;
    left: -175px;
    scale: 0.1;
  }

  50% {
    top: 175px;
    left: 100px;
    rotate: 30deg;
    scale: 1;
  }

  100% {
    top: 60px;
    left: 375px;
    rotate: 10deg;
  }

  /* 100%{
        top: 100px;
        left: 1500px;
    } */
}

@keyframes landingpage-saturn {
  0% {
    top: 252px;
    right: 751px;
    scale: 0;
    rotate: -20deg;
  }

  75% {
    scale: 1;
    rotate: -20deg;
  }

  100% {
    top: 113px;
    right: 150px;
    rotate: 0deg;
  }
}

@keyframes landingpage-telescope {
  0% {
    bottom: -270px;
  }

  50% {
    bottom: -25px;
  }

  100% {
    bottom: -270px;
  }
}

/* media queries */

@media only screen and (max-width: 1350px) {
  .landingpage-section1-ufo {
    left: 200px;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 175px;
      left: 100px;
      rotate: 30deg;
      scale: 1;
    }

    100% {
      top: 60px;
      left: 200px;
      rotate: 10deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    right: 20px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 113px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: -20px;
    height: 430px;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: 120px;
      right: 120px;
      rotate: 10deg;
    }
    100% {
      height: 430px;
      bottom: -20px;
      left: 30px;
      rotate: -20deg;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .landingpage-section1-title {
    font-size: 60px;
    width: 600px;
  }

  .landingpage-section1-ufo {
    left: 200px;
    top: 100px;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 175px;
      left: 100px;
      rotate: 30deg;
      scale: 1;
    }

    100% {
      top: 100px;
      left: 200px;
      rotate: 10deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    right: 20px;
    top: 150px;
    height: 200px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: -20px;
    height: 430px;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: 120px;
      right: 120px;
      rotate: 10deg;
    }
    100% {
      height: 430px;
      bottom: -20px;
      left: 30px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    right: 50px;
  }
}

@media only screen and (max-width: 900px) {
  .landingpage-section1-title {
    font-size: 55px;
    width: 600px;
    bottom: 0px;
  }

  .landingpage-section1-ufo {
    left: 100px;
    top: 150px;
    height: 120px;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    100% {
      top: 150px;
      left: 100px;
      rotate: 10deg;
      scale: 1;
    }

    /* 100%{
            top: 100px;
            left: 100px;
            rotate: 10deg;
        } */

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    right: 20px;
    top: 150px;
    height: 180px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: -20px;
    height: 360px;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: 120px;
      right: 120px;
      rotate: 10deg;
    }
    100% {
      height: 360px;
      bottom: -20px;
      left: 30px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    right: 50px;
  }

  .landingpage-section1-blob3 {
    bottom: 0px;
    left: 50px;
  }
}
@media only screen and (max-width: 760px) {
  .landingpage-section1-title {
    font-size: 55px;
    width: 600px;
    bottom: 0px;
  }

  .landingpage-section1-ufo {
    left: 400px;
    top: 150px;
    height: 150px;
    rotate: 40deg;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 150px;
      left: 200px;
      rotate: 10deg;
      scale: 1;
    }

    100% {
      top: 150px;
      left: 400px;
      rotate: 40deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    display: none;
    right: 20px;
    top: 150px;
    height: 180px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: -20px;
    height: 360px;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: 120px;
      right: 120px;
      rotate: 10deg;
    }
    100% {
      height: 360px;
      bottom: -20px;
      left: 30px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    right: 50px;
  }

  .landingpage-section1-blob3 {
    bottom: 0px;
    left: 50px;
  }
}

@media only screen and (max-width: 670px) {
  .landingpage-section1-title {
    font-size: 55px;
    width: 600px;
    bottom: 0px;
  }

  .landingpage-section1-byline {
    font-size: 22px;
  }

  .landingpage-section1-ufo {
    left: 400px;
    top: 150px;
    height: 150px;
    rotate: 40deg;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 150px;
      left: 200px;
      rotate: 10deg;
      scale: 1;
    }

    100% {
      top: 150px;
      left: 400px;
      rotate: 40deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    display: none;
    right: 20px;
    top: 150px;
    height: 200px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: 20px;
    height: 300px;
    left: 10px;
    animation-duration: 3s, 7s;
    animation-delay: 0s, 3s;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: 120px;
      right: 120px;
      rotate: 10deg;
    }
    100% {
      height: 300px;
      bottom: 20px;
      left: 10px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    display: none;
    right: 20px;
    height: 150px;
  }

  .landingpage-section1-blob1 {
    top: -250px;
  }

  .landingpage-section1-blob2 {
    bottom: -250px;
  }

  .landingpage-section1-blob3 {
    display: none;
  }
}

@media only screen and (max-width: 610px) {
  .landingpage-section1-title {
    font-size: 45px;
    width: 400px;
    bottom: 0px;
  }

  .landingpage-section1-byline {
    font-size: 20px;
  }

  .landingpage-section1-ufo {
    left: 300px;
    top: 150px;
    height: 130px;
    rotate: 40deg;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 150px;
      left: 200px;
      rotate: 10deg;
      scale: 1;
    }

    100% {
      top: 150px;
      left: 300px;
      rotate: 40deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    display: none;
    right: 20px;
    top: 150px;
    height: 200px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: 20px;
    height: 300px;
    left: 10px;
    animation-duration: 3s, 7s;
    animation-delay: 0s, 3s;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: 120px;
      right: 120px;
      rotate: 10deg;
    }
    100% {
      height: 300px;
      bottom: 20px;
      left: 10px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    display: none;
    right: 20px;
    height: 150px;
  }

  .landingpage-section1-blob1 {
    top: -250px;
  }

  .landingpage-section1-blob2 {
    bottom: -250px;
  }

  .landingpage-section1-blob3 {
    display: none;
  }
}

@media only screen and (max-width: 425px) {
  .landingpage-section1-title {
    font-size: 33px;
    width: 300px;
    bottom: 0px;
  }

  .landingpage-section1-byline {
    font-size: 20px;
  }

  .landingpage-section1-ufo {
    left: 220px;
    top: 150px;
    height: 110px;
    rotate: 40deg;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 100px;
      left: 100px;
      rotate: 30deg;
      scale: 1;
    }

    100% {
      top: 150px;
      left: 220px;
      rotate: 40deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    display: none;
    right: 20px;
    top: 150px;
    height: 200px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: 20px;
    height: 280px;
    left: 10px;
    animation-duration: 2.5s, 7s;
    animation-delay: 0s, 2.5s;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: -500px;
      right: -120px;
      rotate: 10deg;
    }
    100% {
      height: 280px;
      bottom: 20px;
      left: 10px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    display: none;
    right: 20px;
    height: 150px;
  }

  .landingpage-section1-blob1 {
    top: -250px;
  }

  .landingpage-section1-blob2 {
    bottom: -250px;
  }

  .landingpage-section1-blob3 {
    display: none;
  }
}

@media only screen and (max-width: 380px) {
  .landingpage-section1-title {
    font-size: 33px;
    width: 300px;
    bottom: 0px;
  }

  .landingpage-section1-byline {
    font-size: 20px;
  }

  .landingpage-section1-ufo {
    left: 220px;
    top: 170px;
    height: 100px;
    rotate: 40deg;
  }

  @keyframes landingpage-ufo {
    0% {
      top: 100px;
      left: -175px;
      scale: 0.1;
    }

    50% {
      top: 100px;
      left: 100px;
      rotate: 30deg;
      scale: 1;
    }

    100% {
      top: 170px;
      left: 220px;
      rotate: 40deg;
    }

    /* 100%{
            top: 100px;
            left: 1500px;
        } */
  }

  .landingpage-section1-saturn {
    display: none;
    right: 20px;
    top: 150px;
    height: 200px;
  }

  @keyframes landingpage-saturn {
    0% {
      top: 252px;
      right: 751px;
      scale: 0;
      rotate: -20deg;
    }

    75% {
      scale: 1;
      rotate: -20deg;
    }

    100% {
      top: 150px;
      right: 20px;
      rotate: 0deg;
    }
  }

  .landingpage-section1-astronaut {
    bottom: 20px;
    height: 260px;
    left: 10px;
    animation-duration: 2.5s, 7s;
    animation-delay: 0s, 2.5s;
  }

  @keyframes landingpage-section1-astronaut {
    0% {
      height: 550px;
      bottom: -500px;
      right: -120px;
      rotate: 10deg;
    }
    100% {
      height: 260px;
      bottom: 20px;
      left: 10px;
      rotate: -20deg;
    }
  }

  .landingpage-section1-telescope {
    display: none;
    right: 20px;
    height: 150px;
  }

  .landingpage-section1-blob1 {
    top: -250px;
    left: -200px;
  }

  .landingpage-section1-blob2 {
    bottom: -250px;
  }

  .landingpage-section1-blob3 {
    display: none;
  }
}
