.landingpage-section2 {
  padding-bottom: 10vh;
  padding-top: 5vh;
  min-height: max(100vh, 720px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-family: "Rubik", sans-serif;
  position: relative;
  background: white;
}

/* .landingpage-section2::before{
  content: '';
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-color: white;
  opacity: .5;
} */

.landingpage-section2-headers {
  display: flex;
  margin: 0 auto;
  width: 89vw;
  justify-content: space-between;
  margin-bottom: 5vh;
  color: white;
  align-items: center;
  font-size: 1rem;
}

.landingpage-section2-headers > b {
  font-size: 3em;
  font-family: Doodle-Font1;
  font-weight: 100;
  /* color: rgb(253, 255, 243); */
  color: black;
}

.landingpage-section2-headers p {
  font-size: 1.35em;
  color: rgb(92, 92, 92);
  font-family: Doodle-Font2;
  transition-duration: 0.5s;
}

.landingpage-section2-headers a {
  text-decoration: none;
}

.landingpage-section2-headers p:hover {
  color: black;
}

.landingpage-section2-content-holder {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  width: 85vw;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 1.5rem;
}

.landingpage-section2-content {
  display: grid;
  grid-template-rows: 100%;
  /* to make the image square div is divided */
  grid-template-columns: 28.57fr 71.43fr;
  background-color: rgb(31, 31, 31);
  width: 32.5%;
  height: calc(0.325 * 85vw / 3.5);
  /* aspect-ratio: 3.5/1; */
  border-radius: 10px;
  box-sizing: border-box;
  padding: 0.8em;
  font-size: 0.78rem;
  transition-duration: 0.5s;
  box-shadow: rgb(92, 92, 92) 0.4em 0.4em 10px;
  text-decoration: none;
}

.landingpage-section2-content:hover {
  scale: 1.05;
  cursor: pointer;
  box-shadow: rgb(92, 92, 92) 0.7em 0.7em 15px;
}

.landingpage-section2-content-num {
  aspect-ratio: 1/1;
  height: 100%;
  width: 100%;
  grid-row: 1;
  grid-column: 1;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.landingpage-section2-content-num b {
  position: absolute;
  color: rgb(145, 145, 145);
  font-size: 2em;
  padding-right: 0.4em;
  padding-bottom: 0.35em;
  top: -0.2em;
  left: -0.2em;
  background-color: rgb(31, 31, 31);
  border-bottom-right-radius: 0.9em;
}

.landingpage-section2-content-num img {
  grid-row: 1;
  grid-column: 2;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  object-fit: cover;
}

.landingpage-section2-content-text {
  padding-left: 1em;
  grid-row: 1;
  grid-column: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.landingpage-section2-content-text p:first-child {
  font-size: 1.4em;
  font-weight: 600;
  /* color: rgb(63, 63, 63); */
  color: white;
}

.landingpage-section2-content-text p:nth-child(2) {
  color: rgb(164, 164, 164);
  padding-left: 1em;
  font-size: 1.1em;
}

@media screen and (min-width: 850px) and (min-height: 900px) {
  .landingpage-section2 {
    padding-top: 3vh;
    row-gap: 3vh;
    padding-bottom: 4vh;
  }
  .landingpage-section2-headers {
    margin-bottom: 2vh;
  }
  .landingpage-section2-content {
    width: 30%;
    /* aspect-ratio: 1.3/1; */
    height: calc(0.3 * 85vw / 1.3);
    position: relative;
    display: block;
  }
  .landingpage-section2-content-num {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 1.3/1; */
  }
  .landingpage-section2-content-text {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    box-sizing: border-box;
    padding: 1em;
    background-color: rgb(31, 31, 31);
    border-radius: 10px;
  }
  .landingpage-section2-content-text p:first-child {
    padding-bottom: 0.5em;
  }
}

@media screen and (max-width: 1080px) and (min-height: 900px) {
  .landingpage-section2-content {
    /* aspect-ratio: 1/1; */
    height: calc(0.3 * 85vw);
    font-size: 0.75rem;
  }
}

@media screen and (min-width: 850px) and (min-height: 1200px) {
  .landingpage-section2-content {
    width: 32%;
    height: calc(0.32 * 85vw * 1.2);
  }
}

@media screen and (max-width: 1400px) and (max-height: 900px) {
  .landingpage-section2-content {
    /* aspect-ratio: 3.5/1; */
    height: calc(0.325 * 85vw / 3.5);
    font-size: 0.68rem;
  }
}

@media screen and (max-width: 1200px) and (max-height: 900px) {
  .landingpage-section2-content-holder {
    width: 90vw;
  }
  .landingpage-section2-headers {
    width: 90vw;
  }
  .landingpage-section2-content {
    /* aspect-ratio: 2.8/1; */
    height: calc(0.325 * 85vw / 2.8);
    /* to make the image square div is divided */
    grid-template-columns: 33.33fr 66.67fr;
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 1000px) and (max-height: 900px) {
  .landingpage-section2-content {
    /* aspect-ratio: 2.6/1; */
    height: calc(0.325 * 85vw / 2.6);
    grid-template-columns: 37.04fr 62.96fr;
    font-size: 0.51rem;
  }
}

@media screen and (max-width: 850px) {
  .landingpage-section2 {
    row-gap: 4rem;
    padding-top: 7vh;
  }
  .landingpage-section2-headers {
    width: 85vw;
    font-size: 1.1rem;
  }
  .landingpage-section2-content-holder {
    justify-content: center;
  }
  .landingpage-section2-content {
    width: 70%;
    /* aspect-ratio: 4/1; */
    height: calc(0.7 * 85vw / 4);
    /* to make the image square div is divided */
    grid-template-columns: 25fr 75fr;
    font-size: 0.76rem;
  }
}

@media screen and (max-width: 600px) {
  .landingpage-section2-headers {
    width: 85vw;
    font-size: 1rem;
  }
  .landingpage-section2-content-holder {
    justify-content: center;
  }
  .landingpage-section2-content {
    width: 70%;
    /* aspect-ratio: 3.5/1; */
    height: calc(0.7 * 85vw / 3.5);
    /* to make the image square div is divided */
    grid-template-columns: 28.57fr 71.43fr;
    font-size: 0.57rem;
  }
}

@media screen and (max-width: 500px) {
  .landingpage-section2-headers {
    font-size: 0.9rem;
  }
  .landingpage-section2-content {
    width: 85%;
    height: calc(0.85 * 85vw / 3.5);
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 400px) {
  .landingpage-section2-headers {
    width: 93%;
    font-size: 0.85rem;
  }
  .landingpage-section2-content {
    width: 90%;
    height: calc(0.9 * 85vw / 3.5);
    font-size: 0.47rem;
  }
}

@media screen and (max-width: 300px) {
  .landingpage-section2-headers {
    font-size: 0.74rem;
  }
  .landingpage-section2-content {
    width: 90%;
    height: calc(0.9 * 85vw / 3.5);
    font-size: 0.4rem;
  }
}
