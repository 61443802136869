@import url("https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap");

@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

@keyframes spin {
  0% {
    --rotate: 0deg;
  }

  100% {
    --rotate: 360deg;
  }
}

/* * {
  font-family: 'Space Mono';
} */

/* body{
  padding-bottom: 1000px;
} */

.contact-principal {
  /* position: absolute; */
  width: 100%;
  height: 600px;
  /* background-color: #16141e; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-family: "Space Mono"; */
  font-family: "Rubik";
  overflow: hidden;
}

/* .contact-central {
  /* position: absolute;
} */

.contact-modal {
  position: relative;
  width: 400px;
  height: 500px;
  transition: all 0.5s;
  transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.contact-photo {
  position: relative;
  width: 400px;
  height: 500px;
  overflow: hidden;
  background-color: rgb(29, 27, 27);
  border-radius: 5px;
  top: -20px;
  float: left;
  z-index: 2;
  transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 1px 1px 20px -5px rgba(0, 0, 0, 0.5);
}

.contact-photo:hover {
  color: rgb(88 199 250 / 100%);
  transition: color 1s;
}

/* .contact-photo:hover:before, .contact-photo:hover:after {
  animation: none;
  opacity: 0;
  transition: .4s;
} */

/* .contact-photo::before {
  content: "";
  width: 104%;
  height: 102%;
  border-radius: 8px;
  background-image: linear-gradient(
    var(--rotate)
    , #5ddcff, #3c67e3 43%, #4e00c2);
    position: absolute;
    z-index: -2;
    top: -1%;
    left: -2%;
    animation: spin 2.5s linear infinite;
} */

.contact-photo::after {
  position: absolute;
  content: "";
  top: calc(var(--card-height) / 6);
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  transform: scale(0.98);
  filter: blur(calc(var(--card-height) / 6));
  /* background-image: linear-gradient(
    var(--rotate)
    , #5ddcff, #3c67e3 43%, #4e00c2); */
  background-color: rgb(29, 27, 27);
  opacity: 1;
  transition: opacity 0.5s;
  animation: spin 2.5s linear infinite;
  border-radius: 3px;
}

.contact-img-back {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  z-index: 2;
}

.contact-img-back .contact-us {
  font-size: 26px;
  font-weight: bold;
  color: black;
}

.contact-img-back .description {
  font-size: 17px;
  text-align: left;
  color: black;
  width: 270px;
}

.location-icon {
  font-size: 20px;
}

.contact-loaction-link:visited {
  color: black;
}

.contact-img-back .contact-welcome-title {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: left;
  padding-left: 10px;
}

.contact-img-back .contact-info {
  height: 55%;
  display: flex;
  color: black;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
  padding: 0 10px;
  cursor: pointer;
  transition: all 0.15s;
}

.contact-info a {
  color: black;
  text-decoration: none;
}

.contact-info .fa {
  transition: all 0.3s;
}

.contact-info .address {
  width: 200px;
}

.contact-info i {
  margin-right: 10px;
}

.contact-info p {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.contact-text-ingredients {
  position: absolute;
  width: 0px;
  top: 30px;
  left: 390px;
  margin-left: 10px;
  height: 410px;
  float: left;
  border-radius: 5px;
  z-index: 3;
  box-shadow: 1px 1px 20px -5px rgba(0, 0, 0, 0.2);
  background-color: rgb(29, 27, 27);
  transition: all 0.5s;
  transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.address-div {
  translate: 26px -20px;
  display: inline-block;
}

.social-media-links {
  display: flex;
  justify-content: flex-start;
}

.social-media-links > a {
  margin: 0 8px;
  color: black;
  font-size: 25px;
  transition: all 0.2s;
}

.social-media-links > a:hover {
  scale: 1.1;
}

.screen-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  clip-path: inset(0 0 0 0);
}

.screen-background-shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen-background-shape1 {
  height: 520px;
  width: 520px;
  /* background: #54bbf2;
  background-image: linear-gradient(
    96.5deg,
    rgba(39, 103, 187, 1) 10.4%,
    rgba(16, 72, 144, 1) 87.7%
  ); */
  background-color: white;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
  box-shadow: 15px 20px 70px -5px rgba(0, 0, 0, 0.2);
}

.screen-background-shape2 {
  height: 220px;
  width: 220px;
  /* background: #76cfff;	 */
  background-image: linear-gradient(
    96.5deg,
    rgba(39, 103, 187, 1) 10.4%,
    rgba(16, 72, 144, 1) 87.7%
  );
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen-background-shape3 {
  height: 540px;
  width: 190px;
  /* background: linear-gradient(270deg, #7fccf5, #54bbf2); */
  /* background-image: linear-gradient(
    96.5deg,
    rgba(39, 103, 187, 1) 10.4%,
    rgba(16, 72, 144, 1) 87.7%
  ); */
  background-color: white;
  top: -24px;
  right: 0;
  border-radius: 32px;
}

.screen-background-shape4 {
  height: 400px;
  width: 200px;
  background: #7e7bb9;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

/* ---------------- Css Tabs -------- */

.contact-tabs {
  position: relative;
  float: left;
  width: 100%;
  height: 60px;
  /* border-bottom: 3px solid white; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-size: 19px;
}

.contact-tabs > ul {
  width: 300px;
  /* background-color: #eee; */
  background-color: rgb(29, 27, 27);
}

.contact-tabs > ul > li {
  position: relative;
  float: left;
  width: 50%;
  list-style: none;
}

.contact-tabs > ul > li > a {
  border-top: 7px solid #ed346c;
  position: relative;
  display: block;
  float: left;
  padding-top: 15px;
  /* color: #241C3E; */
  /* color: #54bbf2; */
  color: black;
  text-decoration: none;
  margin-left: 15px;
  font-size: 14px;
}

.contact-tabs > ul > li:first-child > a {
  border-top: 0px;
  margin-top: 8px;
  /* color: #9A96A4; */
  color: white;
  transition: all 0.5s;
  transition: all 0.2s;
}

.contact-tabs > ul > li:first-child > a:hover {
  border-top: 7px solid #ed346c;
  padding-top: 15px;
  /* color: #241C3E; */
  color: black;
  margin-top: 0px;
}

.contact-title-preparation {
  position: relative;
  float: left;
  margin: 10px 0px 0px 0px;
  width: 410px;
}

.contact-title-preparation > div {
  font-weight: 700;
  font-size: 14px;
  margin-left: 40px;
  text-align: left;
  /* color: #36354E; */
  color: black;
}

.contact-title-preparation > p {
  font-weight: 700;
  font-size: 14px;
  margin-left: 40px;
  margin-top: 5px;
  text-align: left;
  color: black;
}

.contact-title-preparation > .contact-write-your-query {
  color: white;
}

.write-your-query {
  font-size: 15px;
  translate: -55px 25px;
}

.contact-info-preparation > div > input {
  border: none;
  margin: 2px 2px;
  font-size: 16px;
  border-radius: 3px;
  width: 525px;
  height: 24px;
  background-color: #332f2f;
  color: white;
  /* font-family: "Space Mono"; */
  font-family: "Rubik";
  padding-left: 10px;
}

.contact-info-preparation > div > input:focus {
  outline: white;
  border: white;
}

.contact-info-preparation > div > input::placeholder {
  font-size: 16px;
  opacity: 0.5;
  color: white;
}

.contact-info-preparation .submit-button {
  width: 200px;
  height: 24px;
  border: none;
  border-radius: 2px;
  color: black;
  background-color: white;
  margin-left: 170px;
  margin-top: 5px;
  cursor: pointer;
  transition: all 0.15s;
  font-family: "Rubik";
  text-align: center;
}

.contact-info-preparation .submit-button:hover {
  color: rgba(16, 72, 144, 1);
  color: white;
  background-color: black;
}

.contact-info-preparation > div > textarea {
  border: none;
  margin: 40px 2px 0px 2px;
  font-size: 15px;
  border-radius: 3px;
  width: 510px;
  height: 100px;
  resize: none;
  background-color: #332f2f;
  color: white;
  padding: 10px;
  /* font-family: "Space Mono"; */
  font-family: "Rubik";
}

.contact-info-preparation > div > textarea::placeholder {
  font-size: 15px;
  padding: 3px 5px;
  opacity: 0.5;
  color: white;
}

.contact-info-preparation {
  position: relative;
  float: left;
}

.contact-info-preparation > div {
  margin: 10px 0px;
  margin-left: 50px;
  /* border-left: 2px solid white; */
  font-size: 12px;
  padding: 0px;
  padding-left: 50px;
  text-align: left;
  padding-right: 15px;
  color: white;
}

.contact-over-hidden {
  position: relative;
  float: left;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.contact-text-det-preparation {
  position: relative;
  width: 100%;
}

.contact-modal-active > .contact-text-ingredients {
  width: 700px;
  left: 380px;
  z-index: 1;
  box-shadow: 15px 20px 70px -5px rgba(0, 0, 0, 0.2);
}

.contact-modal-active {
  width: 1099px;
}

.contact-modal-active > .contact-photo {
  box-shadow: 25px 10px 70px -5px rgba(0, 0, 0, 0.3);
}

.contact-modal-active > .contact-photo > .contact-servings {
  opacity: 1;
}

.contact-modal-active > .contact-photo > .contact-detalles {
  bottom: 0px;
}

.screen-background-query {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  clip-path: inset(0 0 0 0);
}

.screen-background-query .screen-background-shape {
  transform: rotate(45deg);
  position: absolute;
}

.screen-background-query .screen-background-shape1 {
  height: 520px;
  width: 520px;
  /* background: #54bbf2;	 */
  background-image: linear-gradient(
    96.5deg,
    rgba(39, 103, 187, 1) 10.4%,
    rgba(16, 72, 144, 1) 87.7%
  );
  top: -50px;
  right: 320px;
  border-radius: 0 72px 0 0;
  box-shadow: 15px 20px 70px -5px rgba(0, 0, 0, 0.2);
}

.screen-background-query .screen-background-shape2 {
  height: 220px;
  width: 220px;
  background: #76cfff;
  top: -172px;
  right: 0;
  border-radius: 32px;
}

.screen-background-query .screen-background-shape3 {
  height: 940px;
  width: 180px;
  background: linear-gradient(270deg, #7fccf5, #54bbf2);
  top: -190px;
  right: 90px;
  border-radius: 32px;
  transform: rotate(90deg);
}

.screen-background-query .screen-background-shape4 {
  height: 400px;
  width: 200px;
  background: #7e7bb9;
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

.screen-background-query .screen-background-shape5 {
  width: 96%;
  height: 140px;
  /* background: rgba(16, 72, 144, 1); */
  background: white;
  /* background: linear-gradient(270deg, #7fccf5, #54bbf2); */
  top: 60px;
  right: 0px;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  transform: rotate(0deg);
}

.rocket-image {
  position: absolute;
  bottom: -150px;
  right: -100px;
  height: 400px;
  transform: rotate(45deg);
  transition: all;
  overflow: hidden;
  pointer-events: none;
}

@media only screen and (max-width: 1200px) {
  .contact-modal-active > .contact-text-ingredients {
    width: 500px;
  }

  .write-your-query {
    font-size: 15px;
    translate: -20px 25px;
  }

  .contact-over-hidden {
    width: 500px;
  }

  .screen-background-query {
    width: 500px;
  }

  .contact-info-preparation > div > textarea {
    width: 380px;
  }

  .contact-info-preparation > div {
    margin-left: 20px;
    padding-left: 40px;
  }

  .contact-info-preparation > div > input {
    width: 400px;
    height: 24px;
    padding-left: 10px;
  }

  .contact-modal-active {
    width: 900px;
  }

  .rocket-image {
    position: absolute;
    bottom: -150px;
    right: -100px;
    height: 300px;
    transition: all;
    pointer-events: none;
  }

  .contact-info-preparation .submit-button {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 1040px) {
  .rocket-image {
    bottom: -120px;
    right: -80px;
    height: 250px;
  }
}

@media only screen and (max-width: 1000px) {
  .contact-modal-active {
    width: 800px;
  }

  .contact-photo {
    width: 300px;
  }

  .address-div {
    translate: 23px -21px;
  }

  .contact-img-back .contact-welcome-title {
    height: 30%;
    justify-content: space-around;
  }

  .contact-modal-active > .contact-text-ingredients {
    left: 290px;
  }

  .contact-img-back .contact-us {
    font-size: 20px;
  }

  .contact-img-back .description {
    font-size: 13px;
    width: 200px;
  }

  .contact-img-back .contact-info {
    font-size: 13px;
  }

  .screen-background-shape1 {
    right: 90px;
  }

  .social-media-links > a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .contact-principal {
    overflow: hidden;
    height: 1000px;
  }

  .write-your-query {
    font-size: 15px;
    translate: 0px 25px;
  }

  .contact-modal-active {
    width: 500px;
    height: 900px;
  }

  .contact-photo {
    width: 500px;
  }

  .contact-modal-active > .contact-text-ingredients {
    top: 480px;
    left: -10px;
    width: 500px;
  }

  .contact-text-ingredients {
    top: -20px;
    left: -10px;
    width: 300px;
  }

  .contact-img-back .contact-us {
    font-size: 22px;
  }

  .contact-img-back .description {
    font-size: 16px;
    width: 280px;
  }

  .contact-img-back .contact-info {
    font-size: 16px;
  }

  .screen-background-shape1 {
    right: 190px;
  }

  .contact-over-hidden {
    width: 500px;
  }

  .screen-background-query .screen-background-shape5 {
    height: 140px;
  }

  .screen-background-query {
    width: 500px;
  }

  .contact-info-preparation > div > textarea {
    width: 410px;
  }

  .contact-central {
    top: 100px;
  }

  .rocket-image {
    position: absolute;
    bottom: -80px;
    right: -30px;
    height: 200px;
    transition: all;
    pointer-events: none;
  }

  .contact-info-preparation > div > input {
    width: 430px;
    height: 24px;
    padding-left: 10px;
  }

  .contact-info-preparation > div {
    margin: 10px 0px;
    padding-left: 35px;
  }

  .contact-info-preparation .submit-button {
    margin-left: 150px;
    width: 150px;
  }

  .contact-img-back .contact-info {
    height: 65%;
  }
}

@media only screen and (max-width: 625px) {
  .contact-principal {
    overflow: hidden;
    height: 1039px;
  }

  .contact-img-back .contact-welcome-title {
    translate: 0px 20px;
  }

  .contact-modal-active {
    width: 300px;
    height: 900px;
  }

  .address-div {
    translate: 3px -21px;
  }

  .phone-number {
    translate: 0px 20px;
  }

  .location-icon {
    translate: 0px -35px;
  }

  .contact-photo {
    width: 300px;
  }

  .contact-modal-active > .contact-text-ingredients {
    top: 480px;
    left: -10px;
    width: 300px;
  }

  .contact-text-ingredients {
    top: -20px;
    left: -10px;
    width: 300px;
  }

  .contact-img-back .contact-us {
    font-size: 20px;
  }

  .contact-img-back .description {
    font-size: 13px;
    width: 200px;
  }

  .contact-img-back .contact-info {
    font-size: 13px;
    width: 200px;
  }

  .social-media-links {
    font-size: 10px;
    margin: -30px 0px;
    justify-content: end;
  }

  .contact-info > .social-media-links > a {
    font-size: 20px;
    color: white;
    translate: 90px 30px;
  }

  .contact-loaction-link {
    width: 150px;
  }

  .screen-background-shape1 {
    right: 90px;
  }

  .contact-over-hidden {
    width: 300px;
  }

  .screen-background-query .screen-background-shape5 {
    height: 140px;
  }

  .screen-background-query {
    width: 300px;
  }

  .contact-info-preparation > div > textarea {
    width: 220px;
  }

  .contact-central {
    top: 100px;
  }

  .rocket-image {
    position: absolute;
    bottom: -80px;
    right: -30px;
    height: 200px;
    transition: all;
    pointer-events: none;
  }

  .contact-info-preparation > div > input {
    width: 240px;
    height: 24px;
    padding-left: 10px;
  }

  .contact-info-preparation > div {
    margin: 10px 0px;
    padding-left: 35px;
  }

  .contact-info-preparation .submit-button {
    margin-left: 40px;
    width: 150px;
  }
}
